import React, { useEffect, useState } from "react";
import { Container, Typography, Box, Paper } from "@mui/material";
import Header2 from "../components/Utils/Header2";
import Navigation from "../components/Utils/Navigation";
import "../Styles/StoryMaps.scss";

export default function StoryMapPage(props) {
  const [data, setData] = useState();
  const [showing, setShowing] = useState(true);
  const pathname = window.location.pathname.split("/")[4];

  useEffect(() => {
    fetch(`/api/storymaps/${pathname}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {
        // Handle error here
      });
  }, [pathname]);

  let contentClass = "";

  if (data) {
    switch (data.Content) {
      case "h1":
      case "h2":
      case "h4":
      case "h5":
      case "p":
      case "a":
        contentClass = "left";
        break;
      case "img":
        contentClass = "right";
        break;
      default:
        contentClass = "default";
    }
  }

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Navigation
        showing={showing}
        setShowing={setShowing}
        active="Publications and Reports"
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Header2
          showing={showing}
          setShowing={setShowing}
          active="Publications and Reports"
        />
        <Container>
          <Box sx={{ mt: 3 }}>
            {data && (
              <Paper elevation={3} sx={{ p: 3 }}>
                <Typography variant="h1" gutterBottom>
                  {data.Title}
                </Typography>
                <Typography variant="h2" sx={{ mb: 2 }}>
                  Theme: {data.Category}
                </Typography>
                <Box
                  sx={{
                    textAlign: contentClass === "left" ? "left" : "center",
                    "& img": {
                      maxWidth: "100%",
                      height: "auto",
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: data.Content?.slice(1, -1),
                  }}
                />
              </Paper>
            )}
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
