import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";

export default function AccordionBody(props) {
  const { ID, Question, Answer } = props.item;

  const deleteFaq = () => {
    fetch(`/api/faq/${ID}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("Failed!!!");
      })
      .then((data) => {
        props.setIsErr(data.message);
        window.location.reload();
      })
      .catch((e) => {});
  };

  return (
    <Box sx={{ padding: 2, borderBottom: "1px solid #ddd" }}>
      <Typography variant="h6" gutterBottom>
        {Question}
      </Typography>
      <Typography variant="body1" paragraph>
        {Answer}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton
          onClick={() => {
            props.setShowing(false);
          }}
          edge="end"
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
