import { useEffect, useState } from "react";
import Pagination from "../Utils/Pagination";
import "../../Styles/documents.scss";
import WaveLoading from "../Utils/WaveLoading";
import SelectMain from "../Utils/SelectMain";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDeleteLeft,
  faEdit,
  faEye,
  faLockOpen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { Card, Divider, Grid, Typography } from "@mui/material";

const criteria = ["Title", "Description", "Owner", "Keywords", "Status"];

export default function MapCategory(props) {
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [crit, setCrit] = useState(criteria[0]);
  const [loading, setLoading] = useState(null);
  const [filter, setFilter] = useState("All");
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState(null);
  const srch = useRef();
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (!query) {
      if (filter == "All") {
        setLoading(true);
        fetch(`/api/data/category/${props.category}/${(currentPage - 1) * 12}`)
          .then((res) => {
            if (res.ok) return res.json();
            else throw Error("");
          })
          .then((data) => {
            setLoading(false);
            setData(data);
          })
          .catch((e) => {
            setLoading(false);
          });
      } else {
        setLoading(true);
        fetch(
          `/api/data/tpaginated/${props.category}/${filter}/${
            (currentPage - 1) * 12
          }`
        )
          .then((res) => {
            if (res.ok) return res.json();
            else throw Error("");
          })
          .then((data) => {
            setLoading(false);
            setData(data);
          })
          .catch((e) => {
            setLoading(false);
          });
      }
    } else {
      search(query);
    }
  }, [currentPage, refresh, filter, query]);

  function search(q) {
    setLoading(true);
    fetch(
      `/api/data/category/${props.category}/search/${crit}/${q}/${
        (currentPage - 1) * 12
      }`
    )
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  return (
    <div className="arc">
      <Card sx={{ my: 3, p: 2 }} className="slist">
        <Typography sx={{ my: 1 }} variant="h4">
          {props.category}
        </Typography>
        <Divider />
        <div className="scontainer">
          <div className="smain">
            <br />
            <div className="stop">
              <Grid container className="yr">
                <Typography item xs={8} variant="h4">
                  Search Criteria
                </Typography>
                <SelectMain
                  item
                  xs={4}
                  data={criteria}
                  getSelected={(v) => {
                    setCrit(v);
                  }}
                />
              </Grid>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <input
                  ref={srch}
                  type="text"
                  placeholder={"Searching by " + crit}
                  required
                  onChange={(e) => {
                    if (e.target.value == "") {
                      setData(null);
                      setRefresh(!refresh);
                    } else {
                      setQuery(e.target.value);
                    }
                  }}
                />
                <i className="fa fa-search"></i>
              </form>
            </div>
            <br />
            <div className="mlist">
              {data &&
                data?.data?.length > 0 &&
                data?.data?.map((item, i) => {
                  return (
                    <MyDocument
                      key={i}
                      item={item}
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  );
                })}
            </div>
            <br />
            <br />
            {data && (
              <Pagination
                totalItems={data.total}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </div>
        {loading && <WaveLoading />}
      </Card>
    </div>
  );
}

const MyDocument = (props) => {
  const [status, setStatus] = useState(true);

  useEffect(() => {
    setStatus(props.item.Status);
  }, []);

  let formData = new FormData();

  const deleteMap = () => {
    fetch(`/api/data/${props.item.ID}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        if (data.message) {
          window.location.href = "/portal/maps";
        }
      })
      .catch((e) => {});
  };

  const updateStatus = (status) => {
    formData.append("Status", status);
    fetch(`/api/data/${props.item.ID}`, {
      method: "PUT",
      credentials: "include",
      body: formData,
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        if (data) {
          props.setRefresh(!props.refresh);
        }
      })
      .catch((e) => {});
  };

  return (
    <div
      title={"Map Description" + "\n\n" + props.item.Description}
      className="stk"
    >
      <div className="edit">
        {props.item.Status === true ? (
          <FontAwesomeIcon
            onClick={() => {
              updateStatus(!props.item.Status);
            }}
            className="fa-times"
            color="orange"
            icon={faLockOpen}
          />
        ) : (
          <FontAwesomeIcon
            onClick={() => {
              updateStatus(!props.item.Status);
            }}
            className="fa-times"
            color="gray"
            icon={faLock}
          />
        )}
        <FontAwesomeIcon
          onClick={() => {
            window.location.href = `/portal/maps/preview/${props.item.Category}/${props.item.ID}`;
          }}
          className="fa-times"
          color="green"
          icon={faEye}
        />

        <FontAwesomeIcon
          onClick={() => {
            window.location.href = `/portal/maps/update/${props.item.Category}/${props.item.ID}`;
          }}
          className="fa-times"
          color="brown"
          icon={faEdit}
        />

        <FontAwesomeIcon
          onClick={() => {
            deleteMap();
          }}
          className="fa-times"
          color="red"
          icon={faTrash}
        />
      </div>

      <img src={`/api/${props.item.Thumbnail}`} alt="" />
      <div className="np">
        <Typography
          sx={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: 1,
            lineClamp: 1,
          }}
          variant="h6"
        >
          {props.item.Title}
        </Typography>
        <Typography variant="h4">Owner: {props.item.Owner}</Typography>
        <Typography variant="h4">
          Date Published: {props.item.updatedAt.split("T")[0]}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: 2,
            lineClamp: 2,
          }}
        >
          {props.item.Description}
        </Typography>
      </div>
    </div>
  );
};
