import { useRef, useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  Card,
  Divider,
} from "@mui/material";
import Navigation from "../components/Utils/Navigation";
import Header2 from "../components/Utils/Header2";

export default function FAQsNew(props) {
  const rfQuestion = useRef();
  const rfAnswer = useRef();
  const [body, updateBody] = useState({ Question: null });
  const [msg, setMsg] = useState("");
  const [showing, setShowing] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const newFaq = () => {
    let d = body;
    d.Question = rfQuestion.current.value;
    d.Answer = rfAnswer.current.value;
    updateBody(d);

    if (!body.Question || !body.Answer) {
      setMsg("Inputs cannot be empty!!!");
      setOpenSnackbar(true);
      return;
    }

    fetch("/api/faqs/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error("Something went wrong!!!");
        }
      })
      .then((data) => {
        if (data.success) {
          setSnackbarMsg(data.success);
          setOpenSnackbar(true);
          setTimeout(() => {
            window.location.href = "/portal/faqs";
          }, 1000);
        } else {
          setSnackbarMsg(data.error);
          setOpenSnackbar(true);
        }
      })
      .catch((e) => {
        setSnackbarMsg("Something went wrong!!!");
        setOpenSnackbar(true);
      });
  };

  return (
    <div className="wrapper">
      <Navigation
        showing={showing}
        setShowing={setShowing}
        active="Frequently Asked Questions"
      />
      <div className="MainContent">
        <Header2
          showing={showing}
          setShowing={setShowing}
          active="Frequently Asked Questions"
        />
        <Container>
          <Card sx={{ p: 2, my: 2 }} maxWidth>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6" gutterBottom>
                Create FAQ
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={(e) => e.preventDefault()}
              >
                {msg && (
                  <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackbar(false)}
                  >
                    <Alert
                      onClose={() => setOpenSnackbar(false)}
                      severity={
                        msg.includes("Something went wrong")
                          ? "error"
                          : "success"
                      }
                    >
                      {snackbarMsg}
                    </Alert>
                  </Snackbar>
                )}
                <TextField
                  inputRef={rfQuestion}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Question *"
                  placeholder="Question *"
                  required
                />
                <TextField
                  inputRef={rfAnswer}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  multiline
                  rows={10}
                  label="Answer *"
                  placeholder="Answer *"
                  required
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => newFaq()}
                  sx={{ mt: 2 }}
                  maxWidth
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Card>
        </Container>
      </div>
    </div>
  );
}
