import "../Styles/StoryMaps.scss";
import Header2 from "../components/Utils/Header2";
import Navigation from "../components/Utils/Navigation";
import Input from "../components/Users/UsrInput";
import placeholder from "../assets/imgs/placeholder.png";
import { useEffect, useRef, useState } from "react";
import Compressor from "compressorjs";
import SectionForm from "../components/StoryMaps/AddSection";

export default function StoryMapsCreate() {
  const [showing, setShowing] = useState(true);
  const [isErr, setIsErr] = useState("");
  const [type, setType] = useState(null);
  const [value, setValue] = useState("")
  const preview = useRef();
  const [image, setImage] = useState(placeholder);
  const [storyMapID, setStoryMapID] = useState(null);
  const [msg, setMsg] = useState(null);
  const [pageContent, setPageContent] = useState({ __html: "" });
  const [textContent, setTextContent] = useState({ __html: "" });
  const [entry, setEntry] = useState({ __html: "" });

  const rfTitle = useRef();
  const rfCategory = useRef();
  const rfMyInput = useRef();
  const rfContent = useRef();

  let formData = new FormData();

  const [body, updateBody] = useState({
    Title: null,
    Category: null,
  });

  const validateForm = () => {
    let d = body;
    d.Title = rfTitle.current.value;
    d.Category = rfCategory.current.value;

    updateBody({
      ...body,
      Title: rfTitle.current.value,
      Category: rfCategory.current.value
    });
    submitData();
  };

  const submitData = () => {
    if (checkInputs(body)) {
      fetch("/api/storymaps/create", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          if (data.success) {
            setStoryMapID(data.id);
            setMsg(data.success);
          } else {
            setMsg(data.error);
          }
        })
        .catch((e) => {
          setMsg("Creation failed!");
        });
    }
  };

  const checkInputs = (bdy) => {
    let vl = true;
    if (!bdy.Title || bdy.Title === "") {
      setMsg("Map title is required!");
      return (vl = false);
    }
    if (!bdy.Category || bdy.Category === "") {
      setMsg("Some Category is required!");
      return (vl = false);
    }
    return vl;
  };




  function appendContent(v, t) {
    setType(null)
    let d = "";
    let txt = "";
    switch (t) {
      case "h4":
        txt = textContent.__html
        txt += `<h4>${v}</h4>`
        setTextContent({ ...textContent, __html: txt })
        d = pageContent.__html
        d += `<h4>${v}</h4>`
        setPageContent({ ...pageContent, __html: d })
        break;
      case "h5":
        txt = textContent.__html
        txt += `<h5>${v}</h5>`
        setTextContent({ ...textContent, __html: txt })
        d = pageContent.__html
        d += `<h5>${v}</h5>`
        setPageContent({ ...pageContent, __html: d })
        break;
      case "p":
        txt = textContent.__html
        txt += `<p>${v}</p>`
        setTextContent({ ...textContent, __html: txt })
        d = pageContent.__html
        d += `<p>${v}</p>`
        setPageContent({ ...pageContent, __html: d })
        break;
      case "a":
        txt = textContent.__html
        txt += `<a href="${v}"> ${v}</a>`
        setTextContent({ ...textContent, __html: txt })
        d = pageContent.__html
        d += `<a href="${v}"> ${v}</a>`
        setPageContent({ ...pageContent, __html: d })
        break;
      default:
        break;
    }    
  }

  function submitContent() {
    let fData = new FormData();
    fData.append("Content", textContent.__html);
    fetch(`/api/storymaps/${storyMapID}`, {
      method: "PUT",
      credentials: "include",
      body: fData,
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        if (data.success) {
          window.location.href = "/portal/knowledgehub";
        } else {
          setMsg(data.error);
        }
      })
      .catch((e) => {
        console.log(e)
        setMsg("Creation failed!");
      });
  }

  function uploadImage(v, id) {
    setType(null)
    let fData = new FormData();
    fData.append("Image", v);
    fetch(`/api/storymaps/${id}`, {
      method: "PUT",
      credentials: "include",
      body: fData,
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        if (data.success) {
          let txt = "";
          txt = textContent.__html
          txt += `<img src="/api/uploads/${data.ImageId}">`
          setTextContent({ ...textContent, __html: txt })
          loadImage(data.ImageId)
        } else {
          setMsg(data.error);
        }
      })
      .catch((e) => {
        console.log(e)
        setMsg("Creation failed!");
      });
  }

  function loadImage(id) {
    fetch(`/api/uploads/${id}`)
      .then((res) => {
        if (res.ok) return res.blob();
        else throw Error("");
      })
      .then((blob) => {
        if (blob) {
          let d = pageContent.__html
          const reader = new FileReader();
          reader.onload = () => {
            const dataURL = reader.result;
            d += `<img src="${dataURL}">`
            setPageContent({ ...pageContent, __html: d })
          };
          reader.readAsDataURL(blob);
        }
      })
      .catch((e) => { });
  }

  return (
    <div className="wrapper">
      <Navigation
        showing={showing}
        setShowing={setShowing}
        active="Story Maps"
      />
      <div className="MainContent">
        <Header2
          showing={showing}
          setShowing={setShowing}
          active="Story Maps"
        />
        <div className="storymaps">
          <div className="list">
            <h3>Create story map</h3>
            <hr />
            <span className="err">{msg}</span>
            {storyMapID == null ? <>
              <div className="new">
                <form
                  autoComplete="none"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="step1">
                    <Input
                      ref={rfTitle}
                      type="text"
                      label="Title *"
                      tooltip="Story map title"
                    />

                    <Input ref={rfCategory} type="text" label="Category *" tooltip="Category" />
                    <button onClick={validateForm}
                    >
                      Submit
                    </button>
                  </div>


                </form>
              </div>
              <br />
              <hr />
              <br />
            </> : <div className="step2">
              {body.Title && <h1>{body.Title}</h1>}
              {body.Category && <h2>{"Theme: " + body.Category}</h2>}
              <div dangerouslySetInnerHTML={pageContent}></div>
              <div dangerouslySetInnerHTML={entry}></div>

              {type != null && <div className="myinput">
                <i onClick={() => { setType(null) }} className="fa fa-times"></i>
                <h6>Add pageContent</h6>
                <div className="split">
                  <MyInput setValue={setValue} type={type} />
                  <i onClick={() => {
                    if (type == "img") {
                      uploadImage(value, storyMapID)
                    }
                    else {
                      appendContent(value, type)
                    }

                  }} className='fa fa-plus save'></i>
                </div>

              </div>
              }
              {type == null && <div className="addtitle">
                <h5 onClick={() => {
                  setType("h4")

                }}>Heading 1</h5>
                <h5 onClick={() => {
                  setType("h5")

                }}>Heading 2</h5>
                <h5 onClick={() => {
                  setType("p")

                }}>Paragraph</h5>
                <h5 onClick={() => {
                  setType("img")

                }}>Image</h5>
                <h5 onClick={() => {
                  setType("a")
                }}>Link</h5>
              </div>}
                <button onClick={submitContent}
                >
                  Complete
                </button>
            </div>}


          </div>
        </div>
      </div>
    </div>
  );
}

const MyInput = (props,) => {

  return <div >
    {props.type === "img" ? <input
      onChange={(e) => {
        props.setValue(e.target.files[0])
      }}
      placeholder="Type something..."
      type={props.type == "img" ? "file" : "text"}
      className="minput"
    /> : props.type != "p" ? <input
      onChange={(e) => {
        props.setValue(e.target.value)
      }}
      placeholder="Type something..."
      type={props.type == "img" ? "file" : "text"}
      className="minput"
    /> : <textarea
      onChange={(e) => {
        props.setValue(e.target.value)
      }}
      placeholder="Type something..."
      className="mtext"
      rows="5"
    />}
  </div>
}