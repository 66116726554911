import "../Styles/SingleInstancePage.scss";
import ThematicUpdate from "../components/ThematicMap/ThematicUpdate";
import ThematicPreview from "../components/ThematicMap/ThematicPreview";
import Header2 from "../components/Utils/Header2";
import Navigation from "../components/Utils/Navigation";
import WorldDataPreview from "../components/WorldData/WorldDataPreview";
import WorldDataUpdate from "../components/WorldData/WorldDataUpdate";
import BaseMapPreview from "../components/BaseMap/BaseMapPreview";
import BaseMapUpdate from "../components/BaseMap/BaseMapUpdate";
import { useEffect } from "react";
import { useState } from "react";
import TimeSeriesPreview from "../components/TimeSeries/TimeSeriesPreview";
import TimeSeriesUpdate from "../components/TimeSeries/TimeSeriesUpdate";

export default function SingleInstancePage(props) {
  const pathname = window.location.pathname.split("/");
  const [showing, setShowing] = useState(true);
  const [choice, setChoice] = useState(null);

  const opts = [
    "Agriculture",
    "Climate and Weather",
    "Natural Resources",
    "Spatial Planning",
    "Disaster",
  ];

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {

    handleResize();
    const i = opts
      .map((e) => {
        return e;
      })
      .indexOf(pathname[4].replaceAll("%20", " "));

    if (i !== -1) {
      setChoice("thematic");
    } else {
      if (pathname[4].includes("Raster")) {
        setChoice("raster")
      } else if (pathname[4].includes("General")) {
        setChoice("general")
      } 
      else if (pathname[4].includes("Time")) {
        setChoice("timeseries")
      }
      else window.location.href = "/portal/maps";
    }
  }, []);

  return (
    <div className="wrapper">
      <Navigation
        showing={showing}
        setShowing={setShowing}
        active="Published Instances"
      />
      <div className="MainContent">
        <Header2
          showing={showing}
          setShowing={setShowing}
          active="Single Instance"
        />
        <div className="SingleInstancePage">
          {pathname[3] === "preview" && (
            <>
              {choice && choice === "thematic" && <ThematicPreview />}
              {choice && choice === "raster" && <BaseMapPreview />}
              {choice && choice === "general" && <WorldDataPreview />}
              {choice && choice === "timeseries" && <TimeSeriesPreview />}
            </>
          )}
          {pathname[3] === "update" && (
            <>
              {choice && choice === "thematic" && <ThematicUpdate />}
              {choice && choice === "raster" && <BaseMapUpdate />}
              {choice && choice === "general" && <WorldDataUpdate />}
              {choice && choice === "timeseries" && <TimeSeriesUpdate />}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
