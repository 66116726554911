import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import User from "./User";
import Pagination from "@mui/material/Pagination";

export default function Users(props) {
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [changed, setChanged] = useState(false);
  const [sNo, setSNo] = useState(1);

  useEffect(() => {
    const offset = (currentPage - 1) * 12;
    setData(null);
    setSNo(offset + 1);
    fetch(`/api/users/paginated/${offset}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        }
        return res.json();
      })
      .then((data) => {
        setData(data);
        props.setTotal(data.total);
        props.setActive(data.active);
        props.setInactive(data.inactive);
        var today = new Date();
        var date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        var time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        var dateTime = date + " " + time;
        props.setTime(dateTime);
      })
      .catch((err) => {});
  }, [changed, currentPage]);

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="h3">SN</TableCell>
              <TableCell variant="h3">Name</TableCell>
              <TableCell variant="h3">Email</TableCell>
              <TableCell variant="h3">Phone</TableCell>
              <TableCell variant="h3">Status</TableCell>
              <TableCell variant="h3">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.result &&
              data.result.map((user, index) => {
                return (
                  <User
                    changed={changed}
                    setChanged={setChanged}
                    key={user.UserID}
                    user={user}
                    index={index}
                    sn={index + sNo}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {data && (
        <Pagination
          count={Math.ceil(data.total / 12)}
          page={currentPage}
          onChange={handlePageChange}
          style={{ marginTop: "20px" }}
        />
      )}
    </div>
  );
}
