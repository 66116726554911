import "../Styles/NewInstancesPage.scss";
import { useState } from "react";
import Header2 from "../components/Utils/Header2";
import Navigation from "../components/Utils/Navigation";
import ThematicMap from "../components/ThematicMap/ThematicMap";
import TimeSeries from "../components/TimeSeries/TimeSeries";
import WorldData from "../components/WorldData/WorldData";
import BaseMap from "../components/BaseMap/BaseMap";
import { useEffect } from "react";
import agric from "../assets/imgs/agric.png";
import weather from "../assets/imgs/weather.png";
import natural from "../assets/imgs/natural.png";
import disaster from "../assets/imgs/disaster.png";
import planning from "../assets/imgs/agric.png";
import vectorimg from "../assets/imgs/vector.png";
import rasterimg from "../assets/imgs/raster.png";
import timeimg from "../assets/imgs/time.png";

export default function NewInstancesPage(props) {
  const [type, setType] = useState(null);
  const [showing, setShowing] = useState(true);
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <div className="wrapper">
      <Navigation
        showing={showing}
        setShowing={setShowing}
        active="Publish Instance"
      />
      <div className="MainContent">
        <Header2
          showing={showing}
          setShowing={setShowing}
          active="Publish Instance"
        />
        <div className="NewInstancesPage">
          {!type ? (
            <div className="container">
              <FlipCard
                setType={setType}
                img={agric}
                txt="Agriculture"
                desc="Kenyan Scientists use space technology in conjunction with agriculture to discover how to control and ensure healthy crop growth."
              />
              <FlipCard
                setType={setType}
                img={weather}
                txt="Climate and Weather"
                desc="Kenyan Scientists use space technology in conjunction with agriculture to discover how to control and ensure healthy crop growth."
              />
              <FlipCard
                setType={setType}
                img={natural}
                txt="Natural Resources"
                desc="Satellites generate multiple forms of data for private use and also for the general public in resource management"
              />
              <FlipCard
                setType={setType}
                img={planning}
                txt="Spatial Planning"
                desc="Urban developers use Satellite imaging to closely monitor and support decision making for sustainable urban planning."
              />
              <FlipCard
                setType={setType}
                img={disaster}
                txt="Disaster"
                desc="Satellites can be used to provide critical information to make predictions such as earthquake, locust migrations etc..."
              />
              <FlipCard
                setType={setType}
                img={rasterimg}
                txt="Raster Data"
                desc="Raster data influences many local & global processes and various industries."
              />
              <FlipCard
                setType={setType}
                img={vectorimg}
                txt="General Data"
                desc="Vector data is extremely useful for storing and representing data that has discrete boundaries, such as borders or building footprints, streets and other transport links, and location points."
              />
              <FlipCard
                setType={setType}
                img={timeimg}
                txt="Time Series"
                desc="A time series is a great way to showcase a product history. This could be good for visualizing a dataset changing over time."
              />
            </div>
          ) : (
            <>
              {type === "Agriculture" && (
                <ThematicMap theme={type} setType={setType} />
              )}
              {type === "Climate and Weather" && (
                <ThematicMap theme={type} setType={setType} />
              )}
              {type === "Natural Resources" && (
                <ThematicMap theme={type} setType={setType} />
              )}
              {type === "Spatial Planning" && (
                <ThematicMap theme={type} setType={setType} />
              )}
              {type === "Disaster" && (
                <ThematicMap theme={type} setType={setType} />
              )}
              {type === "Raster Data" && (
                <BaseMap theme={type} setType={setType} />
              )}
              {type === "General Data" && (
                <WorldData theme={type} setType={setType} />
              )}
              {type === "Time Series" && (
                <TimeSeries theme={type} setType={setType} />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const FlipCard = (props) => {
  const [isFlipped, setIsFlipped] = useState(true);

  const handleCardFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="flip">
      <div
        onClick={() => {
          props.setType(props.txt);
        }}
        className="flip-card"
      >
        <div className={`flip-card-inner`}>
          <div className="flip-card-front">
            <div>
              <img src={props.img} alt="" />
              <br />
              <h3>{props.txt}</h3>
            </div>
          </div>

          <div className="flip-card-back">
            <div>
              <h3>Publish Data</h3>
              <p>{props.desc}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
