import React, { forwardRef } from "react";

const UsrSelect = (props, ref) => {
  return (
    <div className="usrselect">
      <h4 htmlFor="select">{props.label}</h4>
      <select ref={ref}>
        {props.data &&
          props.data.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default forwardRef(UsrSelect);
