import React, { forwardRef, useState } from "react";
import { useEffect } from "react";

const TextAreaMap = (props, ref) => {
  const [value, setValue] = useState(props.value);
 
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <div className="input-map">
      <h4 htmlFor="input">{props.label}</h4>
      <textarea
        ref={ref}
        rows="10"
        cols="8"
        type={props.type}
        name="input"
        placeholder={props.placeholder}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        value={value}
      />
    </div>
  );
};

export default forwardRef(TextAreaMap);
