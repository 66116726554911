import React, { forwardRef, useState } from "react";
import { useEffect } from "react";

const InputMap = (props, ref) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <div className="input-map">
      <h4 htmlFor="input">{props.label}</h4>
      {props.select ? (
        <select
          ref={ref}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          name=""
          id=""
        >
          <option value="Select Classification<">Select Data Type</option>
          <option value="Vector">Vector</option>
          <option value="Raster">Raster</option>
        </select>
      ) : (
        <input
          ref={ref}
          type={props.type}
          name="input"
          onChange={(e) => {
            setValue(e.target.value);
          }}
          value={value}
        />
      )}
    </div>
  );
};

export default forwardRef(InputMap);
