import { useState, useEffect } from "react";
import ReplyMessage from "../Messages/ReplyMessage";

import Reply from "./Reply";
import VerifyComment from "../Messages/VerifyComment";
import { Typography } from "@mui/material";

export default function MessageContent(props) {
  const [showLess, setShowLess] = useState(false);
  const [text, setText] = useState(props.message.Content.slice(0, 250) + "...");
  const [status, setStatus] = useState(props.message.Status);
  const [replies, setReplies] = useState();
  const [cl, setCl] = useState("white");
  const [replyMessage, setReplyMessage] = useState(false);
  const [toggleVerify, setToggleVerify] = useState(false);
  const [refreshReplies, setRefreshReplies] = useState(false);
  const [instanceCategory, setInstanceCategory] = useState(null);

  useEffect(() => {
    if (!props.message.Status) {
      setCl("#60606020");
    } else {
      setCl("white");
    }
    setReplies(null);
    fetch(`/api/${props.replies_url}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch messages!!!");
        }
        return res.json();
      })
      .then((data) => {
        setReplies(data);
        props.setTotal(data.total);
        props.setActive(data.active);
        props.setInactive(data.inactive);
        var today = new Date();
        var date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        var time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        var dateTime = date + " " + time;
        props.setTime(dateTime);
      })
      .catch((err) => {});
  }, [refreshReplies]);

  useEffect(() => {
    fetch(`/api/data/${props.message.To}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch messages!!!");
        }
        return res.json();
      })
      .then((data) => {
        setInstanceCategory(data.Category);
      })
      .catch((err) => {});
  }, []);

  const updateToRead = () => {
    fetch(
      props.message.CommentID
        ? `/api/comments/${props.message.CommentID}`
        : `/api/messages/${props.message.MessageID}`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ Status: true }),
      }
    )
      .then((response) => {
        if (response.ok) {
          setStatus(true);
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setCl("white");
      })
      .catch((err) => {});
  };

  return (
    <>
      <div>
        {replyMessage && (
          <ReplyMessage
            currentUser={props.currentUser}
            setReplyMessage={setReplyMessage}
            messageID={
              props.message.MessageID
                ? props.message.MessageID
                : props.message.CommentID
            }
            target={props.target}
            setRefreshReplies={setRefreshReplies}
            refreshReplies={refreshReplies}
          />
        )}
        {toggleVerify && (
          <VerifyComment
            setToggleVerify={setToggleVerify}
            commentID={
              props.message.MessageID
                ? props.message.MessageID
                : props.message.CommentID
            }
            verification={props.message.Verification}
            target={props.target}
            setRefreshReplies={setRefreshReplies}
            refreshReplies={refreshReplies}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
          />
        )}
        <div
          className="accordion-body"
          onClick={() => {
            updateToRead();
          }}
          style={{ backgroundColor: cl }}
        >
          <Typography variant="h4">{props.message.From}</Typography>
          <Typography variant="p">{props.message.Content}</Typography>
          <div className="accordion-footer">
            <Typography
              variant="link"
              onClick={() => {
                setReplyMessage(true);
              }}
            >
              Reply
            </Typography>
            <Typography variant="h6" className="timestamp">
              {props.message.createdAt
                .replace("T", " at ")
                .replace(/:/, ".")
                .split(":")[0]
                .replace(".", ":")}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}
