import React from "react";
import Navigation from "../components/Utils/Navigation";
import Header2 from "../components/Utils/Header2";
import Input from "../components/Users/UsrInput";
import Button from "../components/Utils/ButtonMain";
import Select from "../components/Users/UsrSelect";
import Loading from "../components/Utils/Loading";
import { useState, useRef } from "react";
import { useEffect } from "react";

export default function EditPublication(props) {
  const [isErr, setIsErr] = useState("");
  const [isLoading, setIsLoading] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [data, setData] = useState();

  const types = ["Publication", "Manual"];
  const rfTitle = useRef();
  const rfType = useRef();
  const rfDescription = useRef();
  const rfKeywords = useRef();
  const rfDate = useRef();
  const rfFile = useRef();
  const [body, updateBody] = useState({
    Title: null,
    Type: null,
    Description: null,
    Keywords: null,
    Date: null,
    File: null,
  });
  const [showing, setShowing] = useState(true);
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  // validate form fields
  const validateForm = () => {
    let result = true;
    let d = body;
    d.Title = rfTitle.current.value;
    d.Type = rfType.current.value;
    d.Description = rfDescription.current.value;
    d.Keywords = rfKeywords.current.value;
    d.Date = rfDate.current.value;
    d.File = rfFile.current.files[0];

    updateBody(d);
    setIsErr("");

    if (!body.Title || !body.Type) {
      result = false;
      setIsErr("Title and Type Fields cannot be empty!");
      return result;
    }

    if (!body.Description || body.Description.length < 20) {
      result = false;
      setIsErr("Enter a sufficient description!");
      return result;
    }
    if (!body.File) {
      result = false;
      setIsErr("Upload a file to publish!");
      return result;
    }
    if (!body.Date) {
      result = false;
      setIsErr("Enter a valid date of the publication!");
      return result;
    }
    return result;
  };

  const pathname = window.location.pathname.split("/")[4];

  useEffect(() => {
    fetch(`/api/publications/${pathname}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("could not fetch data!");
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {
     
      });
  }, []);

  const editPublication = () => {
    if (validateForm()) {
      const formData = new FormData();
      for (const i in body) {
        formData.append(i, body[i]);
      }
      fetch(`/api/publications/update/${pathname}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
        
          if (res.ok) {
            return res.json();
          } else {
            throw Error("Change of details failed!!!");
          }
        })
        .then((data) => {
          setIsErr("Update Successful");
        })
        .catch((err) => {
       
        });
    }
  };

  return (
    <div className="wrapper">
      <Navigation
        showing={showing}
        setShowing={setShowing}
        active="Edit Publication"
      />
      <div className="MainContent">
        <Header2
          showing={showing}
          setShowing={setShowing}
          active="Edit Publication"
        />
        <div className="PrivateUsersCreatePage">
          <div className="div2equal">
            <div className="create">
              {data && (
                <form
                  autoComplete="none"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <h2>Edit Publication</h2>
                  <span className="err">{isErr}</span>
                  <Input ref={rfTitle} type="text" label={data.Title} />
                  <Select ref={rfType} label="Select Type" data={types} />
                  <textarea
                    id="w3review"
                    name="w3review"
                    rows="6"
                    cols="80"
                    ref={rfDescription}
                    label="Description *"
                    type="textarea"
                    placeholder={data.Description}
                  />
                  <Input ref={rfKeywords} type="text" label={data.Keywords} />
                  <Input
                    ref={rfDate}
                    type="date"
                    label={data.Date.split("T")[0]}
                  />
                  <input
                    ref={rfFile}
                    type="file"
                    label="Upload file *"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                    accept=".pdf"
                    placeholder={data.File}
                  />

                  <Button label="Submit" handleClick={editPublication} />
                </form>
              )}
              {isLoading && <Loading />}
            </div>
            <div className="descriptions">
              <h2>Points to Note</h2>
              <p>
                This provides an admin interface to edit publications and
                manuals shared with the public These publications and manuals together form our Knowledge Hub.
              </p>
              <p>
                Publications refer to the distribution of copies or phonorecords
                of a work to the public by sale or other transfer of ownership,
                or by rental, lease, or lending.
              </p>
              <p>
                Manuals are the documents that provide instructions or
                guidelines on how to perform an activity and serves as a
                reference on the activity.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
