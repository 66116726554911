import Header2 from "../components/Utils/Header2";
import Navigation from "../components/Utils/Navigation";
import Selector from "../components/Utils/Selector";
import StatsPageBox from "../components/Utils/StatsPageBox";
import ContentEditable from "react-contenteditable";
import { useEffect, useState } from "react";

export default function PublicationInstance(props) {
  const [publication, setPublication] = useState();
  const [msg, setMsg] = useState("");
  let SN = 1;

  const [totalDownloads, setTotalDownloads] = useState();
  const [totalSpecificDownloads, setTotalSpecificDownloads] = useState();
  const [totalVisisforToday, setTotalVisitsforToday] = useState();
  const [totalPageVisisforToday, setTotalPageVisitsforToday] = useState();
  const [time, setTime] = useState();
  const [isError, setIsErr] = useState("");

  const pathname = window.location.pathname.split("/")[3];
  const [showing, setShowing] = useState(true);
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    fetch(`/api/publications/${pathname}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        }
        return res.json();
      })
      .then((data) => {
        setPublication(data);
      })
      .catch((err) => {});
  }, []);

  const deletePublication = () => {
    fetch(`/api/publications/delete/${pathname}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        setMsg(data.message);
        window.location.href = "/portal/publicationscopy";
      })
      .catch((err) => {
  
      });
  };

  const [body, updateBody] = useState({
    Title: null,
    Type: null,
    Description: null,
    Keywords: null,
    Date: null,
    File: null,
  });

  const handleContentEditable = () => {
    fetch(`/api/publications/update/${pathname}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
   
        if (res.ok) {
          return res.json();
        } else {
          throw Error("Change of details failed!!!");
        }
      })
      .then((data) => {
        setIsErr("Update Successful");
      })
      .catch((err) => {
    
      });
  };

  return (
    <div className="wrapper">
      <Navigation
        showing={showing}
        setShowing={setShowing}
        active="Publications and Manuals"
      />
      <div className="MainContent">
        <Header2
          showing={showing}
          setShowing={setShowing}
          active="Publications and Manuals"
        />
        <div className="StatisticsPage">
          <Selector page="Publications and Manuals" />
          <div className="statistics"></div>
          <div className="publications">
            <div className="title">
              <h3>Knowledge Hub</h3>
            </div>
            {publication && (
              <div className="Publication">
                <div>
                  {/* <h1 contentEditable="true">Title : {publication.Title} </h1> */}
                  <h1>
                    <ContentEditable
                      html={publication.Title}
                      data-column="title"
                      className="content-editable"
                      onChange={handleContentEditable}
                    />
                  </h1>
                  <i class="fas fa-edit"></i>
                  <i
                    class="fa fa-trash"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      deletePublication();
                    }}
                  ></i>
                </div>
                <div>
                  <h4>Type : {publication.Type}</h4>
                  <i class="fas fa-edit"></i>
                </div>
                <div>
                  <p>Description : {publication.Description}</p>
                  <i class="fas fa-edit"></i>
                </div>
                <div>
                  <p>Date : {publication.Date.split("T")[0]}</p>
                  <i class="fas fa-edit"></i>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
