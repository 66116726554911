import User from "./User";
import { useEffect, useState } from "react";
import jwt from "jsonwebtoken";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Pagination,
} from "@mui/material";

export default function Users(props) {
  const [data, setData] = useState(null);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [changed, setChanged] = useState(false);
  const [sNo, setSNo] = useState(offset * 12 + 1);
  const [role, setRole] = useState();

  useEffect(() => {
    const token = localStorage.getItem("nimda_ksa");

    if (token) {
      var decoded = jwt.decode(token);
      setRole(decoded.Role);
    } else {
      setRole();
    }
  }, []);

  useEffect(() => {
    setData(null);
    setSNo(offset * 12 + 1);
    fetch(`/api/auth/paginated/${(currentPage - 1) * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        }
        return res.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((err) => {});
  }, [changed, currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="h3">SN</TableCell>
              <TableCell variant="h3">Name</TableCell>
              <TableCell variant="h3">Email</TableCell>
              <TableCell variant="h3">Phone</TableCell>
              <TableCell variant="h3">Role</TableCell>
              <TableCell variant="h3">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data ? (
              data.result.map((user, index) => (
                <User
                  changed={changed}
                  setChanged={setChanged}
                  key={user.UserID}
                  user={user}
                  index={index}
                  sn={index + sNo}
                  role={role}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {data && (
        <Pagination
          count={Math.ceil(data.total / 12)}
          page={currentPage}
          onChange={handlePageChange}
          style={{ marginTop: "20px" }}
        />
      )}
    </Box>
  );
}
