import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import { useEffect, useState } from "react";
// importing pages
import LoginPage from "./Pages/LoginPage";
import StatisticsPage from "./Pages/StatisticsPage";
import MessagesPage from "./Pages/MessagesPage";
import CommentsPage from "./Pages/CommentsPage";
import PaymentsPage from "./Pages/PaymentsPage";
import InstancesPage from "./Pages/InstancesPage";
import SingleInstancePage from "./Pages/SingleInstancePage";
import NewInstancesPage from "./Pages/NewInstancesPage";
import PrivateUsersPage from "./Pages/PrivateUsersPage";
import PrivateUsersCreatePage from "./Pages/PrivateUsersCreatePage";
import PublicUsersPage from "./Pages/PublicUsersPage";
import NotFound from "./Pages/404";
import ProtectedRoute from "./components/login/ProtectedRoute";
import KnowldeHubPage from "./Pages/KnowldeHubPage";
import PublicationsCreatePage from "./Pages/PublicationsCreate";
import PublicationInstance from "./Pages/PublicationInstance";
import EditPublication from "./Pages/EditPublication";
import FAQs from "./Pages/FAQs";
import AOS from "aos";
import FAQsNew from "./Pages/FAQsNew";
import StoryMapsCreate from "./Pages/StoryMapsCreate";
import StoryMapPage from "./Pages/StoryMapPage";
import Downloads from "./Pages/Downloads";
// importing pages

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  const [role, setRole] = useState();

  var jwt = require("jsonwebtoken");

  useEffect(() => {
    const token = localStorage.getItem("nimda_ksa");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        setRole(decoded.Role);
      } catch (error) {}
    } else {
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/portal" element={<StatisticsPage/>} />
        <Route path="/portal/downloads" element={<Downloads/>} />
        <Route path="/portal/faqs" element={<FAQs/>} />
        <Route
          path="/portal/comments"
          element={<CommentsPage/>}
        />
        <Route path="/portal/maps" element={<InstancesPage/>} />

        <Route
          path="/portal/maps/preview/*"
          element={<SingleInstancePage/>}
        />

        <Route
          path="/portal/maps/update/*"
          element={<SingleInstancePage/>}
        />
        <Route
          path="/portal/knowledgehub/storymaps/new"
          element={<StoryMapsCreate/>}
        />

        {(role === "Admin" || role === "Super Admin") && (
          <>
            <Route
              path="/portal/payments"
              element={<PaymentsPage/>}
            />
            <Route
              path="/portal/maps/update/*"
              element={<SingleInstancePage/>}
            />
            <Route
              path="/portal/users/new"
              element={<PrivateUsersCreatePage/>}
            />
            <Route
              path="/portal/publication/update/*"
              element={<EditPublication/>}
            />
            <Route
              path="/portal/knowledgehub/new"
              element={<PublicationsCreatePage/>}
            />
            <Route
              path="/portal/maps/new"
              element={<NewInstancesPage/>}
            />
            <Route
              path="/portal/users"
              element={<PrivateUsersPage/>}
            />
            <Route
              path="/portal/public/users"
              element={<PublicUsersPage/>}
            />
            <Route
              path="/portal/knowledgehub"
              element={<KnowldeHubPage/>}
            />
            <Route
              path="/portal/knowledgehub/storymap/*"
              element={<StoryMapPage/>}
            />
            <Route
              path="/portal/messages"
              element={<MessagesPage/>}
            />
            <Route path="/portal/faqs/new" element={<FAQsNew/>} />
          </>
        )}

        {role === "Regular" && (
          <>
            <Route path="/portal/faqs/new" element={<FAQsNew/>} />
            <Route
              path="/portal/maps/new"
              element={<NewInstancesPage/>}
            />
            <Route
              path="/portal/users"
              element={<PrivateUsersPage/>}
            />
            <Route
              path="/portal/public/users"
              element={<PublicUsersPage/>}
            />
            <Route
              path="/portal/knowledgehub"
              element={<KnowldeHubPage/>}
            />
            <Route
              path="/portal/messages"
              element={<MessagesPage/>}
            />
          </>
        )}
        <Route
          path="/portal/knowledgehub/preview/*"
          element={<PublicationInstance/>}
        />

        {/* <Route exact path="*">
          <NotFound />
        </Route> */}
      </Routes>
    </Router>
  );
}

export default App;
