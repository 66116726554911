import React from "react";
import Loading from "../Utils/Loading";
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  TableRow,
  TableCell,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: false,
      anchorEl: null,
      loading: false,
      color: "#fff",
    };
    this.getId = this.getId.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
    this.activateUser = this.activateUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  componentDidMount() {
    if ((this.props.index + 1) % 2 === 0) {
      this.setState({ color: "#eaf5fd" });
    }
  }

  getId = (e) => {
    this.setState({ value: e.target.checked });
    this.props.getUserId(this.props.user.UserID, e.target.checked);
  };

  toggleOptions = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  activateUser = () => {
    this.setState({ loading: true });
    fetch(`/api/users/${this.props.user.UserID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ Status: !this.props.user.Status }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        this.props.setChanged(!this.props.changed);
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  deleteUser = () => {
    this.setState({ loading: true });
    fetch(`/api/users/${this.props.user.UserID}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        this.props.setChanged(!this.props.changed);
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { anchorEl, loading, color } = this.state;
    const { user, sn } = this.props;

    return (
      <>
        <TableRow hover style={{ backgroundColor: color }}>
          <TableCell>{sn}</TableCell>
          <TableCell>
            <Typography>
              {user.Name.split(" ")
                .map((w) => w[0]?.toUpperCase() + w.substring(1).toLowerCase())
                .join(" ")}
            </Typography>
          </TableCell>
          <TableCell>{user.Email}</TableCell>
          <TableCell>{user.Phone}</TableCell>
          <TableCell>
            {user.Status ? (
              <Typography className="active">Active</Typography>
            ) : (
              <Typography className="inactive">Disabled</Typography>
            )}
          </TableCell>
          <TableCell>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={this.toggleOptions}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              {user.Status ? (
                <MenuItem onClick={this.activateUser}>Deactivate</MenuItem>
              ) : (
                <MenuItem onClick={this.activateUser}>Activate</MenuItem>
              )}
              <MenuItem onClick={this.deleteUser}>Delete</MenuItem>
            </Menu>
          </TableCell>
        </TableRow>
        {loading && <CircularProgress />}
      </>
    );
  }
}
