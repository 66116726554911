import { useRef } from "react";
import Button from "../Utils/ButtonMain";
import ImageUpload from "./ImageUpload";
import InputMap from "./InputMap";
import TextAreaMap from "./TextAreaMap";

export default function AttributeData(props) {
  const title = useRef();
  const description = useRef();
  const dataset = useRef();
  const keywords = useRef();
  const collection = useRef();
  const acquisitionDate = useRef();
  const sourceLink = useRef();
  const owner = useRef();
  const type = useRef();

  const populateMeta = () => {
    let d = props.body;
    d.Title = title.current.value;
    d.Type = type.current.value;
    d.Description = description.current.value;
    d.Dataset = dataset.current.value;
    d.Keywords = keywords.current.value;
    d.Collection = collection.current.value;
    d.AcquisitionDate = acquisitionDate.current.value;
    d.SourceLink = sourceLink.current.value;
    d.Owner = owner.current.value;
    props.setBody(d);
    props.submitData();
  };

  return (
    <div className="cont">
      <InputMap
        ref={title}
        label="Map Title (max 50 words)"
        v="Title"
        style={{ color: "#74465C" }}
        value={props.body.Title}
        placeholder={props.body.Title}
      />
      <TextAreaMap
        ref={description}
        v="Description"
        label="Description (max 300 words)"
        value={props.body.Description}
        placeholder={props.body.Description}
      />

      <div className="div2equal">
        <div>
          <InputMap
            ref={dataset}
            label="Name of the Dataset"
            value={props.body.Dataset}
            placeholder={props.body.Dataset}
            v="Dataset"
            style={{ fontSize: "12pt", color: "#74465C" }}
          />
          <InputMap
            ref={keywords}
            label="Keywords"
            v="Keywords"
            value={props.body.Keywords}
            placeholder={props.body.Dataset}
            style={{ fontSize: "12pt", color: "#74465C" }}
          />
          <InputMap
            ref={collection}
            label="Add to Collection"
            v="Collection"
            value={props.body.Collection}
            placeholder={props.body.Dataset}
            style={{ fontSize: "12pt", color: "#74465C" }}
          />
          <InputMap
            ref={owner}
            label="Owner"
            v="Owner"
            value={props.body.Owner}
            placeholder={props.body.Dataset}
            style={{ fontSize: "12pt", color: "#74465C" }}
          />
          <InputMap
            ref={sourceLink}
            label="Link to Source"
            v="Source"
            value={props.body.SourceLink}
            placeholder={props.body.Dataset}
            style={{ fontSize: "12pt", color: "#74465C" }}
          />
          <InputMap
            // select={true}
            ref={acquisitionDate}
            type="date"
            label="Acquisition Date"
            value={props.body.AcquisitionDate}
            placeholder={props.body.Dataset}
            v="AcquisitionDate"
            style={{ fontSize: "12pt", color: "#74465C" }}
          />
          <InputMap
            select={true}
            ref={type}
            label="Data Type"
            value={props.body.Type}
            placeholder={props.body.Dataset}
            v="Type"
            style={{ fontSize: "12pt", color: "#74465C" }}
          />
        </div>
        <ImageUpload body={props.body} setBody={props.setBody} />
      </div>
      <p>
        By clicking 'Publish Instance', you are confirming the map data
        displayed in this page as well as the analysis and download capabilities
        defined in this section will be accessible to the public.
      </p>
      <Button handleClick={populateMeta} label="Publish Instance" />
    </div>
  );
}
