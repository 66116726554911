import React, { forwardRef } from "react";

const LgInput = (props, ref) => {
  const showPassword = () => {
    ref.current.type = "text";
  };
  const hidePassword = () => {
    ref.current.type = "password";
  };

  return (
    <div className="lg_input">
      <label htmlFor="input">{props.label}</label>
      <input
        autoComplete="none"
        ref={ref}
        type={props.type}
        placeholder={props.placeholder}
        maxLength="50"
      />
      {props.icon && <i className={"fa " + props.icon}></i>}
      {props.type === "password" && (
        <i
          onMouseDown={() => {
            showPassword();
          }}
          onMouseUp={() => {
            hidePassword();
          }}
          className="fa fa-eye"
        ></i>
      )}
    </div>
  );
};

export default forwardRef(LgInput);
