import React, { useRef, useState } from "react";
import Button from "../Utils/ButtonMain";
import { refresh } from "aos";

export default function VerifyComment(props) {
  const [isError, setIsError] = useState("");
  const [body, updateBody] = useState({
    MessageID: null,
    UserID: null,
    UserID: null,
    Content: null,
  });

  const verifyComment = (verify) => {
    fetch(`/api/comments/${props.commentID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ Verification: verify }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        props.setRefresh(!props.refresh);
        props.setToggleVerify(false);
      })
      .catch((err) => {
      });
  };

  return (
    <div className="mypopup">
      <div className="container">
        <i
          onClick={() => {
            props.setToggleVerify(false);
          }}
          className="fa fa-times"
        ></i>
        <h3>
          {props.verification ? "Hide comment on public portal?"
            : "Show comment on public portal?"}
        </h3>
        <p>{isError}</p>
        <button
          onClick={() => {
            verifyComment(props.verification ? false : true);
          }}
        >
          Okay
        </button>
      </div>
    </div>
  );
}
