import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  IconButton,
  Divider,
  Typography,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Home,
  Map,
  Person,
  Help,
  AccountCircle,
  FileCopy,
  Payment,
  Message,
  Comment,
  Download,
} from "@mui/icons-material";
import jwt from "jsonwebtoken";
import logo from "../../assets/imgs/Logo-accent1.png";

export default function Navigation(props) {
  const all = [
    "/",
    "/portal",
    "/portal/downloads",
    "/portal/faqs",
    "https://sandbox.ksa.go.ke",
    "/portal/comments",
    "/portal/maps",
  ];
  const lv1 = [
    "/portal/maps/new",
    "/portal/users",
    "/portal/public/users",
    "/portal/knowledgehub",
    "/portal/messages",
    "/portal/faqs/new",
  ];
  const lv2 = [
    "/portal/payments",
    "/portal/users/new",
    "/portal/knowledgehub/new",
    "/portal/knowledgehub/storymaps/new",
  ];
  const [role, setRole] = useState();
  const [open, setOpen] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("nimda_ksa");
    if (token) {
      try {
        const decoded = jwt.decode(token);
        setRole(decoded.Role);
      } catch (error) {
        logout();
      }
    } else {
      logout();
    }
  }, []);

  const logout = () => {
    fetch("/api/auth/logout", {
      method: "get",
      credentials: "include",
    })
      .then((res) => res.json())
      .then(() => {
        localStorage.clear();
        window.location.href = "/";
      })
      .catch((error) => {});
  };

  const handleClick = (key) => {
    setOpen({ ...open, [key]: !open[key] });
  };

  const renderItem = (item, index) => {
    const a = all.indexOf(item?.url);
    const l1 = lv1.indexOf(item?.url);
    const l2 = lv2.indexOf(item?.url);

    if (a !== -1) {
      return (
        <ListItem button key={index} component="a" href={item.url}>
          <ListItemText primary={item.txt} />
        </ListItem>
      );
    } else if (
      l1 !== -1 &&
      (role === "Super Admin" || role === "Admin" || role === "Regular")
    ) {
      return (
        <ListItem button key={index} component="a" href={item.url}>
          <ListItemText primary={item.txt} />
        </ListItem>
      );
    } else if (l2 !== -1 && (role === "Super Admin" || role === "Admin")) {
      return (
        <ListItem
          button
          key={index}
          onClick={() =>
            item.url === "https://sandbox.ksa.go.ke"
              ? window.open(item.url, "_blank")
              : (window.location.href = item.url)
          }
        >
          <ListItemText primary={item.txt} />
        </ListItem>
      );
    }
  };

  const renderOptions = (options, key) => (
    <Collapse in={open[key]} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {options.map((item, i) => renderItem(item, i))}
      </List>
    </Collapse>
  );

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: props.showing ? 250 : "fit-content",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: props.showing ? 250 : "fit-content",
          boxSizing: "border-box",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 2,
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{ maxWidth: props.showing ? "140px" : "30px" }}
        />
      </Box>
      <Divider />
      <List>
        <ListItem button onClick={() => handleClick("home")}>
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary="Home" />
          {open["home"] ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {renderOptions(
          [
            { txt: "Statistics", url: "/portal" },
            { txt: "Downloads", url: "/portal/downloads" },
            { txt: "Messages", url: "/portal/messages" },
            { txt: "Comments", url: "/portal/comments" },
            { txt: "Payments", url: "/portal/payments" },
          ],
          "home"
        )}

        <ListItem button onClick={() => handleClick("gis")}>
          <ListItemIcon>
            <Map />
          </ListItemIcon>
          <ListItemText primary="GIS" />
          {open["gis"] ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {renderOptions(
          [
            { txt: "Maps", url: "/portal/maps" },
            { txt: "New", url: "/portal/maps/new" },
          ],
          "gis"
        )}

        {role !== "Guest" && (
          <>
            <ListItem button onClick={() => handleClick("users")}>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText primary="Users" />
              {open["users"] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {renderOptions(
              [
                { txt: "Users", url: "/portal/users" },
                { txt: "New", url: "/portal/users/new" },
              ],
              "users"
            )}

            <ListItem button onClick={() => handleClick("customers")}>
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText primary="Customers" />
              {open["customers"] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {renderOptions(
              [{ txt: "Users", url: "/portal/public/users" }],
              "customers"
            )}
          </>
        )}

        <ListItem button onClick={() => handleClick("knowledgeHub")}>
          <ListItemIcon>
            <FileCopy />
          </ListItemIcon>
          <ListItemText primary="Documents" />
          {open["knowledgeHub"] ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {renderOptions(
          [
            { txt: "List", url: "/portal/knowledgehub" },
            { txt: "New", url: "/portal/knowledgehub/new" },
          ],
          "knowledgeHub"
        )}

        <ListItem button onClick={() => handleClick("faqs")}>
          <ListItemIcon>
            <Help />
          </ListItemIcon>
          <ListItemText primary="FAQs" />
          {open["faqs"] ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {renderOptions(
          [
            { txt: "List", url: "/portal/faqs" },
            { txt: "New", url: "/portal/faqs/new" },
          ],
          "faqs"
        )}
      </List>
      {props.showing && (
        <Box sx={{ p: 2, textAlign: "center" }}>
          <Typography variant="body2">
            Powered by <br />
            <a
              href="https://osl.co.ke"
              target="_blank"
              rel="noopener noreferrer"
            >
              Oakar Services
            </a>
          </Typography>
        </Box>
      )}
    </Drawer>
  );
}
