import Header2 from "../components/Utils/Header2";
import Navigation from "../components/Utils/Navigation";
import { useEffect, useState } from "react";
import StackedAreaChart from "../components/Stats/StackedAreaChart";
import CustomShapeBar from "../components/Stats/CustomShapeBar";
import { TopItem } from "../components/Home/TopItem";
import { Card, Typography } from "@mui/material";

export default function StatisticsPage(props) {
  const [totalVisits, setTotalVisits] = useState();
  const [total, setTotal] = useState([]);

  const [totalDownloads, setTotalDownloads] = useState();
  const [totalSpecificDownloads, setTotalSpecificDownloads] = useState();
  const [totalVisisforToday, setTotalVisitsforToday] = useState();
  const [pageVisitsToday, setPageVisitsToday] = useState();
  const [time, setTime] = useState();
  const [stats, setStats] = useState();
  const [users, setUsers] = useState(0);
  const [showing, setShowing] = useState(true);
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    fetch("/api/data/stats/categorized", {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setStats(data);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/stats/total`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch messages!!!");
        }
        return res.json();
      })
      .then((data) => {
        setTotalVisits(data.total);
        var today = new Date();
        var date = today.getDate() + " " + +" " + today.getFullYear();

        const mins =
          today.getMinutes() < 10
            ? "0" + today.getMinutes().toString()
            : today.getMinutes();
        var time = today.getHours() + ":" + mins;
        var dateTime = date + " " + time;
        setTime(dateTime);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/downloads/total`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch messages!!!");
        }
        return res.json();
      })
      .then((data) => {
        setTotalDownloads(data.total);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/stats/pages`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch messages!!!");
        }
        return res.json();
      })
      .then((data) => {
        let d = [];
        data.result?.forEach((element) => {
          d.push({ name: element.Page, value: parseInt(element.total) });
        });

        let x = d.sort(function (a, b) {
          return b.total - a.total;
        });
        setTotal(x);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/downloads/download`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch messages!!!");
        }
        return res.json();
      })
      .then((data) => {
        let d = [];
        data.result?.forEach((element) => {
          d.push({ name: element.Theme, value: parseInt(element.total) });
        });

        let x = d.sort(function (a, b) {
          return b.total - a.total;
        });
        setTotalSpecificDownloads(x);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/stats/totalByCurrentDate`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch messages!!!");
        }
        return res.json();
      })
      .then((data) => {
        setTotalVisitsforToday(data.total);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/stats/pagesByCurrentDate`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch messages!!!");
        }
        return res.json();
      })
      .then((data) => {
        let d = [];
        data.result?.forEach((element) => {
          d.push({ name: element.Page, value: parseInt(element.total) });
        });

        let x = d.sort(function (a, b) {
          return b.total - a.total;
        });
        setPageVisitsToday(x);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/users/paginated/0`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        }
        return res.json();
      })
      .then((data) => {
        setUsers(data.total);
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="wrapper">
      <Navigation
        active="Portal Statistics"
        showing={showing}
        setShowing={setShowing}
      />
      <div className="MainContent">
        <Header2
          active="Portal Statistics"
          showing={showing}
          setShowing={setShowing}
        />
        <div className="StatisticsPage">
          <div className="top">
            <TopItem
              title="Visits"
              date={time}
              value={totalVisits}
              color="#C9EEFF"
            />
            <TopItem
              title="Downloads"
              date={time}
              value={totalDownloads}
              color="#F1DEC9"
            />
            <TopItem
              title="Maps"
              date={time}
              value={stats?.total}
              color="#FBFFB1"
            />
            <TopItem title="Users" date={time} value={users} color="#FFA3FD" />
          </div>
          <div className="middle">
            <Card elevation={1}>
              <div className="chart">
                <Typography variant="h3">Total Page Visits</Typography>
                <StackedAreaChart data={total} />
              </div>
            </Card>
            <Card elevation={1}>
              <div className="chart">
                <Typography variant="h3">Page Visits Today</Typography>
                <StackedAreaChart data={pageVisitsToday} />
              </div>
            </Card>
          </div>
          <div className="bottom">
            <Card elevation={1}>
              <div className="chart">
                <Typography variant="h3">Map Categories</Typography>
                <CustomShapeBar data={stats?.result} aspect={2} />
              </div>
            </Card>
            <div className="rd">
              <RowData
                name="Messages"
                index={0}
                icon="fa-envelope-open"
                value={stats?.messages}
              />
              <RowData name="Comments" index={1} icon="fa-comment" value={0} />
              <RowData
                name="Knowledge Hub"
                index={2}
                code="&#xf1ea;"
                icon=" fa-newspaper-o"
                value={stats?.publications}
              />
              <RowData
                name="Payments"
                index={3}
                icon="fa-credit-card"
                value={0}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const RowData = (props) => {
  const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "red", "pink"];

  return (
    <Card elevation={1} className="row">
      <i style={{ color: colors[props.index] }} className={"fa " + props.icon}>
        {props.code}
      </i>
      <div>
        <Typography variant="h4">{props.name}</Typography>
        <Typography variant="p">{props.value}</Typography>
      </div>
    </Card>
  );
};
