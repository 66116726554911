export default function AlertMsg(props) {
  return (
    <div className="model">
      <div className="alert">
        <p>{props.msg}</p>
        <h4
          onClick={() => {
            setTimeout(() => {
              if (props.msg === "Submitted successfully!") {
                window.location.href = "/portal/maps";
              }
            }, 1000);
            props.setMsg(null);
          }}
        >
          Okay
        </h4>
      </div>
    </div>
  );
}
