import { useRef, useState } from "react";
import Input from "./LgInput";
import Button from "../Utils/ButtonMain";
import Loading from "../Utils/Loading";
import ForgotPassword from "./forgotPassword";

export default function LgForm(props) {
  const [body, updateBody] = useState({
    Email: null,
    Password: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState("");
  const rfEmail = useRef();
  const rfPassword = useRef();

  const [toggleForgotPassword, showForgotPassword] = useState(false);
  var jwt = require("jsonwebtoken");

  const login = () => {
    let d = body;
    d.Email = rfEmail.current.value.toLowerCase().trim();
    d.Password = rfPassword.current.value;
    updateBody(d);
    setIsError("");

    if (!validateEmail(body.Email))
      return setIsError("Please enter a valid email address!");
    if (!validatePassword(body.Password))
      return setIsError("Password must be at least 6 characters!");

    if (validateEmail(body.Email) && validatePassword(body.Password)) {
      setIsLoading(false);
      fetch("/api/auth/login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          if (data.success) {
            if (jwt.decode(data.token).Role === "Portal Guest") {
              window.location.href = "/portal/maps";
            } else {
              window.location.href = "/portal";
            }

            localStorage.setItem("nimda_ksa", data.token);
          } else {
            setIsError(data.error);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          setIsError("Login failed!");
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  return (
    <div className="login">
      {toggleForgotPassword && (
        <ForgotPassword showForgotPassword={showForgotPassword} />
      )}
      <form
        autoComplete="none"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <h3>Admin Login</h3>
        <span className="err">{isError}</span>
        <Input
          ref={rfEmail}
          type="email"
          label="Email Address *"
          icon="fa-at"
        />
        <Input
          ref={rfPassword}
          type="password"
          label="Password *"
          icon="fa-lock"
        />
        <p>
          Forgot password? .
          <span
            onClick={() => {
              showForgotPassword(true);
            }}
          >
            Click Here
          </span>
        </p>
        <Button
          handleClick={() => {
            login();
          }}
          label="Login"
        />

        {isLoading && <Loading />}
      </form>
    </div>
  );
}
