import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Paper,
  Button,
  Card,
  Divider,
} from "@mui/material";
import Header2 from "../components/Utils/Header2";
import Navigation from "../components/Utils/Navigation";
import AccordionBody from "../components/Utils/AccordionBody";
import WaveLoading from "../components/Utils/WaveLoading";
import Pagination from "../components/Utils/Pagination";
import placeholder from "../assets/imgs/placeholder.png";
import { pdfjs } from "react-pdf";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

export default function KnowledgeHubPage(props) {
  const [publications, setPublications] = useState([]);
  const [show, setShow] = useState(false);
  const [showing, setShowing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("Publications");
  const [refresh, setRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const srch = useRef();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleResize = () => {
    setShowing(window.innerWidth >= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggle = () => {
    setShow(!show);
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const endpoint =
          filter === "Story Maps"
            ? `/api/storymaps/paginated/${(currentPage - 1) * 12}`
            : `/api/publications/type/${filter}/${(currentPage - 1) * 12}`;
        const res = await fetch(endpoint);
        if (res.ok) {
          const data = await res.json();
          setPublications(data.result || data);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, refresh, filter]);

  const search = async (q) => {
    setLoading(true);
    try {
      const res = await fetch(`/api/publications/search/${q}`);
      if (res.ok) {
        const data = await res.json();
        setPublications(data);
      } else {
        throw new Error("Failed to search");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="wrapper" sx={{ display: "flex", height: "100vh" }}>
      <Navigation
        showing={showing}
        setShowing={setShowing}
        active="Publications and Reports"
      />
      <div component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Header2
          showing={showing}
          setShowing={setShowing}
          active="Publications and Reports"
        />
        <Container>
          <Card sx={{ p: 5, my: 3 }}>
            <Grid2 container sx={{ display: "flex" }} spacing={3}>
              <Box item md={8} sx={{ display: "flex", flexGrow: 1, mb: 2 }}>
                {["Publications", "Reports", "Learning Materials", "Other"].map(
                  (category) => (
                    <Button
                      key={category}
                      onClick={() => {
                        setFilter(category);
                        setCurrentPage(1);
                      }}
                      variant={filter === category ? "contained" : "outlined"}
                      sx={{ mr: 1 }}
                    >
                      {category}
                    </Button>
                  )
                )}
              </Box>
              <Box item md={4} sx={{ display: "flex", mb: 2 }}>
                <TextField
                  ref={srch}
                  variant="outlined"
                  placeholder="Type to search"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setPublications([]);
                      setRefresh(!refresh);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        onClick={() => {
                          if (srch.current.value !== "") {
                            search(srch.current.value);
                          }
                        }}
                      >
                        Search
                      </Button>
                    ),
                  }}
                />
              </Box>
            </Grid2>
            <Divider sx={{ my: 3 }} />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {publications && publications.length > 0 ? (
                <>
                  {filter === "Story Maps"
                    ? publications.map((item, i) => (
                        <StoryMap key={i} item={item} />
                      ))
                    : publications.map((item, i) => (
                        <MyDocument key={i} item={item} />
                      ))}
                  <Pagination
                    totalItems={publications.total || 0}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </>
              ) : (
                <Typography paragraph>No documents found</Typography>
              )}
            </Box>
            {loading && <WaveLoading />}
          </Card>
        </Container>
      </div>
    </div>
  );
}

const Category = ({ txt, filter, setFilter, setCurrentPage }) => (
  <Button
    onClick={() => {
      setFilter(txt);
      setCurrentPage(1);
    }}
    variant={txt === filter ? "contained" : "outlined"}
    sx={{ mb: 1, mr: 1 }}
  >
    {txt}
  </Button>
);

const MyDocument = ({ item }) => {
  const [blob, setBlob] = useState(null);
  const [firstPageBlob, setFirstPageBlob] = useState(null);

  useEffect(() => {
    const fetchPdfFirstPage = async (blob) => {
      try {
        const pdf = await pdfjs.getDocument(blob).promise;
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 0.5 });
        const canvas = document.createElement("canvas");
        const canvasContext = canvas.getContext("2d");
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        await page.render({ canvasContext, viewport }).promise;
        setFirstPageBlob(canvas.toDataURL());
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };

    const fetchPdf = async () => {
      try {
        const res = await fetch(`/api/uploads/${item.File}`);
        if (res.ok) {
          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);
          setBlob(url);
          fetchPdfFirstPage(url);
          return () => window.URL.revokeObjectURL(url);
        } else {
          throw new Error("Failed to fetch PDF");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchPdf();
  }, [item.File]);

  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Box sx={{ display: "flex" }}>
        <img
          src={firstPageBlob || placeholder}
          alt=""
          style={{ maxWidth: "100px", marginRight: "16px" }}
        />
        <Box>
          <Typography variant="h5" gutterBottom>{item.Title}</Typography>
          <Typography variant="p">{item.Description}</Typography>
          <Typography variant="p">Category: {item.Type}</Typography>
          {blob && (
            <a href={blob} target="_blank" rel="noopener noreferrer">
              View/Download <i className="fa fa-file-pdf-o" />
            </a>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

const StoryMap = ({ item }) => (
  <Paper
    sx={{ p: 2, mb: 2 }}
    onClick={() =>
      (window.location.href = `/portal/knowledgehub/preview/${item.StoryMapID}`)
    }
  >
    <Box sx={{ display: "flex" }}>
      <img
        src={
          item?.Images?.length > 0
            ? `/api/uploads/${item.Images[0]}`
            : placeholder
        }
        alt=""
        style={{ maxWidth: "100px", marginRight: "16px" }}
      />
      <Box>
        <Typography variant="h6">{item.Title}</Typography>
        <Typography>Category: {item.Category}</Typography>
        <a href={`knowledgehub/storymap/${item.StoryMapID}`}>
          Click to view more
        </a>
      </Box>
    </Box>
  </Paper>
);
