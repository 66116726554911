import { useRef } from "react";
import Input from "../Maps/InputMap";
import Button from "../../components/Utils/ButtonMain";
import { useState } from "react";

export default function New(props) {
  const rfQuestion = useRef();
  const rfAnswer = useRef();
  const [body, updateBody] = useState({ Question: null });
  const [msg, setMsg] = useState("");

  const newFaq = () => {
    let d = body;
    d.Question = rfQuestion.current.value;
    d.Answer = rfAnswer.current.value;
    updateBody(d);

    if (!body.Question || !body.Answer) {
      return setMsg("Inputs cannot be empty!!!");
    }

    fetch("/faqs/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error("Something went wrong!!!");
        }
      })
      .then((data) => {
        if (data.success) {
          setMsg(data.success);
          setTimeout(() => {
            props.setShow(false);
          }, 1000);
          window.location.reload();
        }
      })
      .catch((e) => {
       
      });
  };

  return (
    <div className="mypopup">
      <div className="container">
        <p>{msg}</p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Input
            ref={rfQuestion}
            type="text"
            label="Question *"
            placeholder="Question *"
          />{" "}
          <br />
          <textarea
            id="w3review"
            name="w3review"
            rows="6"
            cols="80"
            ref={rfAnswer}
            placeholder="Answer *"
            type="textarea"
          />
          <Button label="Submit" handleClick={newFaq} />
        </form>
        <h4
          onClick={() => {
            props.setShow(false);
          }}
        >
          Cancel
        </h4>
      </div>
    </div>
  );
}
