import React from "react";
import Loading from "../Utils/Loading";
import EditUserDetails from "./EditDetails";
import DetailsForm from "./DetailsForm";
import {
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  CircularProgress,
  TableRow,
  TableCell,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: false,
      anchorEl: null,
      loading: false,
      editDetails: false,
      detailsForm: false,
      color: "#fff",
    };
    this.getId = this.getId.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
    this.toggleEditForm = this.toggleEditForm.bind(this);
    this.toggleDetailsForm = this.toggleDetailsForm.bind(this);
  }

  componentDidMount() {
    if ((this.props.index + 1) % 2 === 0) {
      this.setState({ color: "#eaf5fd" });
    }
  }

  getId = (e) => {
    this.setState({ value: e.target.checked });
    this.props.getUserId(this.props.user.UserID, e.target.checked);
  };

  toggleOptions = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  toggleEditForm = () => {
    this.setState({ editDetails: !this.state.editDetails });
  };

  toggleDetailsForm = () => {
    this.setState({ detailsForm: !this.state.detailsForm });
  };

  activateUser = () => {
    this.setState({ loading: true });
    fetch(`/api/auth/${this.props.user.UserID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ Status: !this.props.user.Status }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        this.props.setChanged(!this.props.changed);
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  deleteUser = () => {
    this.setState({ loading: true });
    fetch(`/api/auth/${this.props.user.UserID}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        this.props.setChanged(!this.props.changed);
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { anchorEl, loading, editDetails, detailsForm, color } = this.state;
    const { user, sn, role, changed, setChanged } = this.props;

    return (
      <>
        <TableRow
          hover
          onClick={this.toggleOptions}
          style={{ backgroundColor: color }}
        >
          <TableCell>{sn}</TableCell>
          <TableCell>
            {user.Name.split(" ")
              .map((w) => w[0]?.toUpperCase() + w.substring(1).toLowerCase())
              .join(" ")}
          </TableCell>
          <TableCell>{user.Email}</TableCell>
          <TableCell>{user.Phone}</TableCell>
          <TableCell>{user.Role}</TableCell>
          <TableCell>{user.Status ? "Active" : "Disabled"}</TableCell>
          <TableCell>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={this.toggleOptions}
            >
              <MoreVertIcon />
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {user.Role !== "Super Admin" && role === "Admin" && (
                <MenuItem onClick={this.handleClose}>Close</MenuItem>
              )}
              {user.Role !== "Super Admin" && role === "Admin" && (
                <MenuItem onClick={this.toggleEditForm}>Edit Details</MenuItem>
              )}
              {role === "Super Admin" && (
                <MenuItem onClick={this.toggleDetailsForm}>
                  Edit Details
                </MenuItem>
              )}
              {user.Status
                ? user.Role !== "Super Admin" && (
                    <MenuItem onClick={this.activateUser}>Deactivate</MenuItem>
                  )
                : user.Role !== "Super Admin" && (
                    <MenuItem onClick={this.activateUser}>Activate</MenuItem>
                  )}
              {user.Role !== "Super Admin" && (
                <MenuItem onClick={this.deleteUser}>Delete</MenuItem>
              )}
            </Menu>
          </TableCell>
        </TableRow>
        {loading && <Loading />}
        {user.Role !== "Super Admin" && editDetails && (
          <EditUserDetails
            toggleEditForm={this.toggleEditForm}
            setChanged={setChanged}
            changed={changed}
            userID={user.UserID}
            name={user.Name}
            phone={user.Phone}
            position={user.Position}
            department={user.Department}
          />
        )}
        {detailsForm && (
          <DetailsForm
            toggleDetailsForm={this.toggleDetailsForm}
            setChanged={setChanged}
            changed={changed}
            userID={user.UserID}
            name={user.Name}
            phone={user.Phone}
            position={user.Position}
            department={user.Department}
          />
        )}
      </>
    );
  }
}
