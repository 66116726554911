import { forwardRef, React, useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  ResponsiveContainer,
  YAxis,
  Tooltip,
  Label,
} from "recharts";
import MyError from "../../../Utils/MyError";

const Barchart = (props, ref) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (props.body?.style.classes?.length > 0) {
      let d = [];
      props.body?.style.classes.forEach((item) => {
        d.push({ name: item.name, value: item.count, fill: item.color });
      });
      setData(d);
      setTimeout(props.setReload(!props.reload), 10);
    }
  }, [props.body?.style.classes]);

  return (
    <div>
      {data.length > 0 ? (
        <div ref={ref}>
          <BarChart
            margin={{ left: -24, right: 10 }}
            width={300}
            height={300}
            data={data}
            id="myChart"
          >
            <XAxis dataKey="name"></XAxis>
            <YAxis></YAxis>
            <Tooltip />
            <Bar dataKey="value" />
          </BarChart>
        </div>
      ) : (
        <MyError
          txt="No charts to display at the moment. Go to layers to style your
        data"
        />
      )}
    </div>
  );
};

export default forwardRef(Barchart);
