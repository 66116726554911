import "../Styles/Publications.scss";
import React from "react";
import Navigation from "../components/Utils/Navigation";
import Header2 from "../components/Utils/Header2";
import Accordion from "../components/FAQs/Accordion FAQs";
import AccordionBody from "../components/FAQs/AccordionBody FAQs";
import New from "../components/FAQs/New";
import { useState, useEffect } from "react";
import { Divider, Typography } from "@mui/material";

export default function FAQs(props) {
  const [data, setData] = useState();
  const [show, setShow] = useState(false);
  const [isError, setIsErr] = useState("");
  const [showing, setShowing] = useState(true);
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);
  useEffect(() => {
    fetch(`/api/faqs`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("Something went wrong!!!");
      })
      .then((data) => {
        setData(data);
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="wrapper">
      <Navigation
        showing={showing}
        setShowing={setShowing}
        active="Frequently Asked Questions"
      />
      <div className="MainContent">
        <Header2
          showing={showing}
          setShowing={setShowing}
          active="Frequently Asked Questions"
        />
        <div className="publications">
          <div className="list">
            <Typography>Frequently Asked Questions</Typography>

            <Divider sx={{ my: 2 }} />

            <div className="content">
              {data &&
                data.map((item, index) => {
                  return (
                    <AccordionBody
                      key={index}
                      item={item}
                      setIsErr={setIsErr}
                    />
                  );
                })}
            </div>
          </div>
          {show && <New setShow={setShow} />}
          {isError}
        </div>
      </div>
    </div>
  );
}
