export default function Selector(props) {
    
    return <div className="Utils">
                <div className="selector">
                    <select className="thisSelector"> 
                        <option value="0">Showing {props.page} for today</option>
                        <option value="1">Showing {props.page} for this week</option>
                        <option value="2">Showing {props.page} for this month</option>
                    </select>
                </div>
            </div>

}