import { Box, Card, Divider, Typography } from "@mui/material";
import ImageUpload from "./ImageUpload";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

export default function PreviewData(props) {
  return (
    <Card className="desc">
      <Typography variant="h3" gutterBottom>
        {props.body.Title}
      </Typography>
      <Divider sx={{ my: 2 }} />

      <Grid2 container spacing={3}>
        <Grid2 item md={8}>
          <Typography sx={{ textAlign: "left" }} variant="p" gutterBottom>
            {props.body.Description}
          </Typography>

          <Typography variant="h4">
            <span>Dataset: </span>
            {props.body.Dataset}
          </Typography>
          <Typography variant="h4">
            <span>Keywords: </span>
            {props.body.Keywords}
          </Typography>
          <Typography variant="h4">
            <span>Collection: </span>
            {props.body.Collection ? props.body.Collection : `None`}
          </Typography>
          <Typography variant="h4">
            <span>Owner: </span>
            {props.body.Owner}
          </Typography>
          {props.body.SourceLink && (
            <Typography variant="h4">
              <span>Link: </span>
              <a
                className="sourcelink"
                href={props.body.SourceLink}
                target="_blank"
              >
                Go to Source
              </a>
            </Typography>
          )}
          <Typography variant="h4">
            <span>Date of Capture: </span>
            {props.body.AcquisitionDate ? props.body.AcquisitionDate : `N/A`}
          </Typography>
          <Typography variant="h4">
            <span>Classification Type: </span>
            {props.body?.Data[0]?.style?.type} Classification
          </Typography>
        </Grid2>
        <Grid2 item md={4}>
          <ImageUpload
            preview={true}
            body={props.body}
            updateBody={props.updateBody}
          />
        </Grid2>
      </Grid2>
    </Card>
  );
}
