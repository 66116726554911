import { useEffect, useState } from "react";
import TopPanel from "../Maps/TopPanel";
import Basemaps from "../BaseMaps/Basemaps";
import AttributeData from "../Maps/AttributeData";
import AlertMsg from "../Maps/AlertMsg";
import View from "ol/View";
import XYZ from "ol/source/XYZ";
import TileLayer from "ol/layer/Tile";
import myData from "../../assets/data/data";
import WMTSCapabilities from "ol/format/WMTSCapabilities.js";
import WMTS, { optionsFromCapabilities } from "ol/source/WMTS.js";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Text from "ol/style/Text";
import {
  ScaleLine,
  ZoomToExtent,
  defaults as defaultControls,
} from "ol/control";
import { fromLonLat } from "ol/proj.js";
import { useRef } from "react";
import RightPanel from "../Maps/RightPanel";
import Data from "../Maps/SidePanel/Data";
import Query from "../ThematicMap/Query";
import RippleLoading from "../Utils/RippleLoading";
import MyStyler from "../Maps/SidePanel/Topo/MyStyler";
import { Map } from "ol";
import Graticule from "ol/layer/Graticule";
export default function ThematicMap(props) {
  let template = {
    Title: "",
    Category: props.theme,
    Description: "",
    Thumbnail: "",
    Dataset: "",
    Keywords: "",
    Collection: "",
    AcquisitionDate: "",
    SourceLink: "",
    Owner: "",
    Type: "",
    Data: [],
    Status: "",
  };
  const parser = new WMTSCapabilities();
  const [body, setBody] = useState(template);
  const [msg, setMsg] = useState(null);
  const [basemap, setBasemap] = useState(new TileLayer({ title: "Basemap" }));
  const [ke_counties, setke_counties] = useState(
    new TileLayer({ title: "Kenya Counties" })
  );
  const [map, setMap] = useState(null);
  const [dataSelector, setDataSelector] = useState(null);
  const [styleSelector, setStyleSelector] = useState(null);
  const [querySelector, setQuerySelector] = useState(null);
  const mapElement = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(3);
  const [baseSelector, setBaseSelector] = useState(null);
  const [extent, setExtent] = useState([
    3774864.0391037, -526481.967965136, 4665413.10605419, 514854.269936751,
  ]);
  const [graticule, setGraticule] = useState(
    new Graticule({
      strokeStyle: new Stroke({
        color: "rgba(0,0,0,0.5)",
        width: 1,
        lineDash: [0.5, 20],
      }),
      latLabelStyle: new Text({
        font: "12px Calibri,sans-serif",
        textBaseline: "bottom",
        fill: new Fill({
          color: "rgba(0,0,0,1)",
        }),
        stroke: new Stroke({
          color: "rgba(255,255,255,1)",
          width: 3,
        }),
      }),
      showLabels: true,
      wrapX: false,
      title: "Grid",
    })
  );
  const [pChartImgUrl, setPChartImgUrl] = useState();
  const [bChartImgUrl, setBChartImgUrl] = useState();

  let formData = new FormData();

  useEffect(() => {
    basemap.setSource(
      new XYZ({
        url: myData[0].url,
        crossOrigin: "anonymous",
      })
    );

    fetch(
      encodeURI(
        `/geoserver/gwc/service/wmts?REQUEST=GetCapabilities&format=xml`
      ),
      {
        method: "get",
        credentials: "include",
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch messages!!!");
        }
        return res.text();
      })
      .then((text) => {
        const result = parser.read(text);
        const options = optionsFromCapabilities(result, {
          layer: `Geoportal_Rasters:Kenya_Counties`,
          matrixSet: "EPSG:4326",
        });

        ke_counties.setSource(new WMTS(options));
      })
      .catch((e) => {});

    const initialMap = new Map({
      controls: defaultControls().extend([
        new ZoomToExtent({
          extent: extent,
        }),
        new ScaleLine({
          units: "metric",
          bar: true,
          text: "Scale",
        }),
      ]),
      target: mapElement.current,
      layers: [basemap, ke_counties, graticule],
      renderer: "canvas",
      view: new View({
        projection: "EPSG:3857",
        center: fromLonLat([37.9062, -0.0236], "EPSG:3857"),
        zoom: 6,
      }),
    });

    initialMap?.on("moveend", function (e) {});

    initialMap?.on("singleclick", function (event) {
      initialMap.forEachFeatureAtPixel(
        event.pixel,
        function (feature, layer) {}
      );
    });
    setExtent(initialMap.getView().calculateExtent(initialMap.getSize()));
    setMap(initialMap);
  }, []);

  const submitData = () => {
    if (checkInputs(body)) {
      formData.append("Title", body.Title);
      formData.append("Description", body.Description);
      formData.append("Dataset", body.Dataset);
      formData.append("Type", body.Type);
      formData.append("Owner", body.Owner);
      formData.append("Category", body.Category);
      formData.append("Collection", body.Collection);
      formData.append("AcquisitionDate", body.AcquisitionDate);
      formData.append("SourceLink", body.SourceLink);
      formData.append("Thumbnail", body.Thumbnail, body.Thumbnail?.name);
      formData.append("Keywords", body.Keywords);
      formData.append("Data", JSON.stringify(body.Data));
      fetch("/api/data/create", {
        method: "POST",
        credentials: "include",
        body: formData,
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setMsg("Submitted successfully!");
        })
        .catch((e) => {
          setMsg("Creation failed!");
        });
    }
  };

  const checkInputs = (bdy) => {
    let vl = true;
    if (bdy.Data.length < 1) {
      setMsg("Please load some spatial data!");
      return (vl = false);
    }
    if (!bdy.Title || bdy.Title === "") {
      setMsg("Map title is required!");
      return (vl = false);
    }
    if (!bdy.Description || bdy.Description === "") {
      setMsg("Map description is required!");
      return (vl = false);
    }
    if (bdy.Thumbnail === "") {
      setMsg("Please upload a thumbnail!");
      return (vl = false);
    }
    if (!bdy.Dataset || bdy.Dataset === "") {
      setMsg("Please load some spatial data!");
      return (vl = false);
    }
    if (!bdy.Keywords || bdy.Keywords === "") {
      setMsg("At least 1 keyword is required!");
      return (vl = false);
    }
    if (!bdy.Owner || bdy.Owner === "") {
      setMsg("Data owner is required!");
      return (vl = false);
    }
    if (!bdy.Type || bdy.Type === "") {
      setMsg("Data Type is required!");
      return (vl = false);
    }

    return vl;
  };

  const printMap = () => {
    map.once("rendercomplete", function () {
      const mapCanvas = document.createElement("canvas");
      const size = map.getSize();
      mapCanvas.width = size[0];
      mapCanvas.height = size[1];
      const mapContext = mapCanvas.getContext("2d");
      Array.prototype.forEach.call(
        map.getViewport().querySelectorAll(".ol-layer canvas, canvas.ol-layer"),
        function (canvas) {
          if (canvas.width > 0) {
            const opacity =
              canvas.parentNode.style.opacity || canvas.style.opacity;
            mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
            let matrix;
            const transform = canvas.style.transform;
            if (transform) {
              // Get the transform parameters from the style's transform matrix
              matrix = transform
                .match(/^matrix\(([^\(]*)\)$/)[1]
                .split(",")
                .map(Number);
            } else {
              matrix = [
                parseFloat(canvas.style.width) / canvas.width,
                0,
                0,
                parseFloat(canvas.style.height) / canvas.height,
                0,
                0,
              ];
            }
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(
              mapContext,
              matrix
            );
            const backgroundColor = canvas.parentNode.style.backgroundColor;
            if (backgroundColor) {
              mapContext.fillStyle = backgroundColor;
              mapContext.fillRect(0, 0, canvas.width, canvas.height);
            }
            mapContext.drawImage(canvas, 0, 0);
          }
        }
      );
      mapContext.globalAlpha = 1;
      mapContext.setTransform(1, 0, 0, 1, 0, 0);

      var link = document.createElement("a");
      link.setAttribute("href", mapCanvas.toDataURL());
      link.setAttribute("download", "map.png");
      document.body.appendChild(link);
      link.click();
    });
    map.renderSync();
  };

  return (
    <div>
      <div className="map">
        <div
          ref={mapElement}
          style={{ width: "100%", height: "80vh" }}
          id="map"
        ></div>
        {isLoading && <RippleLoading />}
        <div
          onClick={() => {
            setBaseSelector(true);
          }}
          className="base_selector"
        >
          <i className="fa fa-map"></i>
        </div>
        <div className="download">
          <div>
            <a
              onClick={() => {
                printMap();
              }}
              role="button"
            >
              <i className="fa fa-camera"></i> Screenshot
            </a>
          </div>
        </div>
        {baseSelector && (
          <Basemaps
            setBaseSelector={setBaseSelector}
            basemap={basemap}
            selected={selected}
            setSelected={setSelected}
          />
        )}
        <RightPanel
          map={map}
          body={body}
          setBody={setBody}
          setIsLoading={setIsLoading}
          pChartImgUrl={pChartImgUrl}
          setPChartImgUrl={setPChartImgUrl}
          bChartImgUrl={bChartImgUrl}
          setBChartImgUrl={setBChartImgUrl}
        />
        <TopPanel
          dataSelector={dataSelector}
          setDataSelector={setDataSelector}
          setStyleSelector={setStyleSelector}
          setQuerySelector={setQuerySelector}
        />
        {dataSelector && (
          <Data
            map={map}
            setIsLoading={setIsLoading}
            setDataSelector={setDataSelector}
            setExtent={setExtent}
            body={body}
            setBody={setBody}
          />
        )}
        {querySelector && (
          <Query
            map={map}
            setIsLoading={setIsLoading}
            setQuerySelector={setQuerySelector}
            setExtent={setExtent}
            body={body}
            setBody={setBody}
          />
        )}
        {styleSelector && (
          <MyStyler
            map={map}
            setStyleSelector={setStyleSelector}
            body={body}
            setBody={setBody}
          />
        )}
      </div>
      <div className="attribute">
        <AttributeData body={body} submitData={submitData} setBody={setBody} />
      </div>
      {msg && <AlertMsg msg={msg} setMsg={setMsg} />}
    </div>
  );
}
