import Navigation from "../components/Utils/Navigation";
import Header2 from "../components/Utils/Header2";
import { useEffect, useState, useRef } from "react";
import "../Styles/DownloadsPage.scss";
import Loading from "../components/Utils/Loading";
import Pagination from "../components/Utils/Pagination";
import CustomShapeBar from "../components/Stats/CustomShapeBar";
import StackedAreaChart from "../components/Stats/StackedAreaChart";
import { TopItem } from "../components/Home/TopItem";
import { Card, Typography } from "@mui/material";

export default function Downloads(props) {
  const [showing, setShowing] = useState(true);
  const [allDownloads, setAllDownloads] = useState();
  const [time, setTime] = useState();
  const [type, setType] = useState();
  const [showType, setShowType] = useState(false);
  const [owner, setOwner] = useState();
  const [showOwner, setShowOwner] = useState(false);
  const [date, setDate] = useState(false);
  const [categs, setCategs] = useState();
  const [showCategs, setShowCategs] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [stats, setStats] = useState();
  const [yrStats, setYrStats] = useState();

  const rfStartDate = useRef();
  const rfEndDate = useRef();

  const fetchDownloadsByType = (typ) => {
    if (typ === "All") AllDownloads();
    fetch(`/api/downloads/type/${typ}/${(currentPage - 1) * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setAllDownloads(data);
      })
      .catch((error) => console.log(error));
  };
  const fetchByOwner = (owner) => {
    if (owner === "All") AllDownloads();
    setIsLoading(false);
    fetch(`/api/downloads/owner/${owner}/${(currentPage - 1) * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setAllDownloads(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const fetchByDate = (start, end) => {
    fetch(`/api/downloads/date/${start}/${end}/${(currentPage - 1) * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setAllDownloads(data);
      })
      .catch((error) => console.log(error));
  };
  const fetchByCateg = (categ) => {
    fetch(`/api/downloads/category/${categ}/${(currentPage - 1) * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setAllDownloads(data);
      })
      .catch((error) => console.log(error));
  };

  // types
  const getTypes = () => {
    fetch(`/api/downloads/type`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setType(data);
      })
      .catch((error) => console.log(error));
  };

  const getOwners = () => {
    fetch(`/api/downloads/owner`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setOwner(data);
      })
      .catch((error) => console.log(error));
  };

  const getCategories = () => {
    fetch(`/api/downloads/categs`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setCategs(data);
      })
      .catch((error) => console.log(error));
  };

  const AllDownloads = () => {
    setIsLoading(false);
    fetch(`/api/downloads/${(currentPage - 1) * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setAllDownloads(data);
        setIsLoading(false);
        var today = new Date();
        var date =
          today.getFullYear() +
          "-" +
          today.toLocaleString("en-us", { month: "short" }) +
          "-" +
          today.getDate() +
          "th";
        var time = today.getHours() + ":" + today.getMinutes();
        var dateTime = date + " " + time;
        setTime(dateTime);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    AllDownloads();
    getCategories();
    getTypes();
    getByYear();
  }, [currentPage]);

  useEffect(() => {
    fetch(`/api/downloads/total`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setStats(data);
      })
      .catch((error) => console.log(error));
  }, []);

  const getByYear = (year) => {
    if (!year) {
      year = new Date().getFullYear();
    }
    fetch(`/downloads/year/${year}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setYrStats(data);
      })
      .catch((error) => console.log(error));
  };

  function formatDate(date) {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = new Date(date).toLocaleDateString("en-US", options);

    // Remove the comma from the formatted date
    const formattedDateWithoutComma = formattedDate.replace(/,/g, "");

    // Split the formatted date into day, month, and year parts
    const [month, day, year] = formattedDateWithoutComma.split(" ");

    return `${day} ${month}, ${year}`;
  }

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="wrapper">
      <Navigation
        active="Downloads"
        showing={showing}
        setShowing={setShowing}
      />
      <div className="MainContent">
        <Header2 active="Downloads" showing={showing} setShowing={setShowing} />
        <div className="DownloadsPage">
          <div className="top">
            <TopItem
              title="Total"
              date={time}
              value={stats?.total}
              color="#C9EEFF"
            />
            <TopItem
              title="Previous month"
              date={time}
              value={stats?.previousMonth}
              color="#F1DEC9"
            />
            <TopItem
              title="Last seven days"
              date={time}
              value={stats?.lastSevenDays}
              color="#FBFFB1"
            />
            <TopItem
              title="Today"
              date={time}
              value={stats?.today}
              color="#FFA3FD"
            />
          </div>
          {/* graphs */}
          <div className="middle">
            <Card className="chart">
              <Typography variant="h3">Downloads by Categories</Typography>
              <CustomShapeBar data={categs} aspect={2} />
            </Card>
            <Card className="chart">
              <Typography variant="h3">Downloads by Type</Typography>
              <CustomShapeBar data={type} aspect={2} />
            </Card>
          </div>
          <div className="time-chart">
            <Card className="chart">
              <div className="two">
                <h3>Downloads Over Time</h3>
                <select
                  onChange={(e) => {
                    getByYear(e.target.value);
                  }}
                >
                  <option value="">Select Year</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                </select>
              </div>

              <StackedAreaChart data={yrStats} />
            </Card>
          </div>
          <Card className="list">
            <Typography variant="h3">Downloaded datasets</Typography>
            <div className="row">
              <div className="col">
                <div className="wrap">
                  <select
                    onChange={(e) => {
                      if (e.target.value === "Type") {
                        getTypes();
                        setShowType(true);
                        setShowOwner(false);
                        setDate(false);
                        setShowCategs(false);
                      } else if (e.target.value === "Owner") {
                        getOwners();
                        setShowOwner(true);
                        setShowType(false);
                        setShowCategs(false);
                        setDate(false);
                      } else if (e.target.value === "Date range") {
                        setDate(true);
                        setShowOwner(false);
                        setShowType(false);
                        setShowCategs(false);
                      } else if (e.target.value === "Category") {
                        getCategories();
                        setShowCategs(true);
                        setShowOwner(false);
                        setShowType(false);
                        setDate(false);
                      } else {
                        setShowType(false);
                        setShowOwner(false);
                        setDate(false);
                        setShowCategs(false);
                        AllDownloads();
                      }
                    }}
                  >
                    <option value="All">Filter By</option>
                    <option value="Category">Category</option>
                    <option value="Type">Type</option>
                    <option value="Owner">Owner</option>
                    <option value="Date range">Date range</option>
                  </select>
                  {type && showType && (
                    <select
                      onChange={(e) => {
                        fetchDownloadsByType(e.target.value);
                      }}
                    >
                      <option value="All">All</option>
                      {type.map((item, index) => {
                        return (
                          <option key={index} value={item.name}>
                            {item.name.replace(
                              /^./,
                              item.name[0].toUpperCase()
                            )}
                          </option>
                        );
                      })}
                    </select>
                  )}
                  {owner && showOwner && (
                    <select
                      onChange={(e) => {
                        fetchByOwner(e.target.value);
                      }}
                    >
                      <option value="All">All</option>
                      {owner.map((item, index) => {
                        return (
                          <option key={index} value={item.OtherInfo}>
                            {item.OtherInfo}
                          </option>
                        );
                      })}
                    </select>
                  )}
                  {categs && showCategs && (
                    <select
                      onChange={(e) => {
                        fetchByCateg(e.target.value);
                      }}
                    >
                      <option value="All">All</option>
                      {categs.map((item, index) => {
                        return (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  )}
                  {date && (
                    <>
                      <form action="" onSubmit={(e) => e.preventDefault()}>
                        <div className="date">
                          <label htmlFor="Start Date">Start Date</label>
                          <input type="date" ref={rfStartDate} />
                        </div>
                        <div className="date">
                          <label htmlFor="End Date">End Date</label>
                          <input type="date" ref={rfEndDate} />
                        </div>
                        <button
                          onClick={() => {
                            fetchByDate(
                              rfStartDate.current.value,
                              rfEndDate.current.value
                            );
                          }}
                        >
                          Search
                        </button>
                      </form>
                    </>
                  )}
                </div>

                <div>
                  <div className="head">
                    <h4>SN</h4>
                    <h4>Dataset</h4>
                    <h4>Category</h4>
                    <h4>Type</h4>
                    <h4>Owner</h4>
                    <h4>Geoserver Url</h4>
                    <h4>Download date</h4>
                  </div>
                  {loading ? (
                    <Loading />
                  ) : (
                    <div className="content">
                      {allDownloads &&
                        allDownloads?.rows?.map((item, index) => {
                          return (
                            <div
                              className="row"
                              key={index}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#fff" : "#eaf5fd",
                              }}
                            >
                              <p>{index + 1}</p>
                              <p>{item.Download}</p>
                              <p>{item.Category}</p>
                              <p>
                                {item.Type.replace(
                                  /^./,
                                  item.Type[0].toUpperCase()
                                )}
                              </p>
                              <p>{item.OtherInfo}</p>
                              <p>{truncateString(item.GeoserverURL, 10)}</p>
                              <p>{formatDate(item.createdAt)}</p>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {allDownloads && (
              <Pagination
                totalItems={allDownloads.count}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
}
