import React, { useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function EditUserDetails(props) {
  const [isError, setIsError] = useState("");
  const [msg, setMsg] = useState("");
  const [body, updateBody] = useState({
    Name: null,
    Phone: null,
    Position: null,
    Department: null,
  });

  const rfPhone = useRef();
  const rfName = useRef();
  const rfDept = useRef();
  const rfPositiion = useRef();

  const editDetails = () => {
    const d = body;
    d.Phone = rfPhone.current.value;
    d.Name = rfName.current.value;
    d.Position = rfPositiion.current.value;
    d.Department = rfDept.current.value;

    updateBody(d);
    setIsError("");
    fetch(`/api/auth/${props.userID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("Update Failed!");
      })
      .then((data) => {
        setMsg("Change User Details Successful");
        props.setChanged(!props.changed);
      })
      .catch((e) => {
        setIsError("Update Failed!!");
      });
  };

  const phonePlaceholder = `${props.phone}`;
  const namePlaceholder = `${props.name}`;
  const deptPlaceholder = `${props.department}`;
  const posPlaceholder = `${props.position}`;

  return (
    <Dialog open onClose={props.toggleEditForm} fullWidth maxWidth="sm">
      <DialogTitle>
        Edit Account Details
        <IconButton
          aria-label="close"
          onClick={props.toggleEditForm}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography color="error">{isError}</Typography>
        <Typography color="primary">{msg}</Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            editDetails();
          }}
          autoComplete="off"
        >
          <TextField
            inputRef={rfName}
            label="Change Name"
            type="text"
            placeholder={namePlaceholder}
            fullWidth
            margin="normal"
          />
          <TextField
            inputRef={rfPhone}
            label="Change Phone Number"
            type="number"
            placeholder={phonePlaceholder}
            fullWidth
            margin="normal"
          />
          <TextField
            inputRef={rfDept}
            label="Change Department"
            type="text"
            placeholder={deptPlaceholder}
            fullWidth
            margin="normal"
          />
          <TextField
            inputRef={rfPositiion}
            label="Change Position"
            type="text"
            placeholder={posPlaceholder}
            fullWidth
            margin="normal"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.toggleEditForm} color="secondary">
          Cancel
        </Button>
        <Button onClick={editDetails} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
