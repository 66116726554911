import Function from "./LgFunction";
export default function LgBottom(props) {
  return (
    <div className="bottom">
      <Function txt="Public Portal" />
      <Function txt="Data and Copyright" />
      <Function txt="Privacy Policy" />
      <Function txt="Terms and Conditions" />
    </div>
  );
}
