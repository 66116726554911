import placeholder from "../../assets/imgs/placeholder.png";
import { useEffect, useRef, useState } from "react";
import Compressor from "compressorjs";

export default function ImageUpload(props) {
  const file = useRef();
  const preview = useRef();
  const [image, setImage] = useState(placeholder);
  const [compressedFile, setCompressedFile] = useState(null);

  useEffect(() => {
    if (props.body.Thumbnail !== "") {
      if (typeof props.body.Thumbnail !== "object") {
        setImage("/api/" + props.body.Thumbnail);
      }
    }
  }, [props.body.Thumbnail]);

  const handleCompressedUpload = (e) => {
    const image = e.target.files[0];
    new Compressor(image, {
      quality: 0.4,
      success: (res) => {
        let d = props.body;
        d.Thumbnail = res;
        props.setBody(d);
        setImage(URL.createObjectURL(res));
      },
      error(err) {
       
      },
    });
  };

  const getFile = () => {
    file.current.click();
  };

  return (
    <div className="photo">
      {!props.preview ? (
        <>
          <h3>Upload a thumbnail</h3>
        </>
      ) : (
        <h3>Map Thumbnail</h3>
      )}
      <div className="img">
        <img ref={preview} src={image} alt="" />
        <input
          ref={file}
          type="file"
          accept="image/png, image/gif, image/jpeg"
          onChange={(e) => handleCompressedUpload(e)}
        />
        {!props.preview && (
          <i
            className="fa fa-plus"
            onClick={() => {
              getFile();
            }}
          ></i>
        )}
      </div>
    </div>
  );
}
