import "../Styles/users.scss";
import Header2 from "../components/Utils/Header2";
import Navigation from "../components/Utils/Navigation";
import Users from "../components/PbcUsers/Users";
import { useState } from "react";
import { useEffect } from "react";
import { Card, Divider, Typography } from "@mui/material";

export default function PublicUsersPage(props) {
  const [total, setTotal] = useState(0);
  const [active, setActive] = useState(0);
  const [inactive, setInactive] = useState(0);
  const [time, setTime] = useState("");
  const [showing, setShowing] = useState(true);
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <div className="wrapper">
      <Navigation
        showing={showing}
        setShowing={setShowing}
        active="Public Users"
      />
      <div className="MainContent">
        <Header2
          showing={showing}
          setShowing={setShowing}
          active="Public Users"
        />
        <div className="users">
          <Card className="list">
            <Typography variant="h3">Public Users</Typography>
            <Divider sx={{ my: 2 }} />
            <Users
              setTotal={setTotal}
              setActive={setActive}
              setInactive={setInactive}
              setTime={setTime}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}
