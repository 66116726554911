import "../Styles/InstancesPage.scss";
import { useEffect, useState } from "react";
import Header2 from "../components/Utils/Header2";
import MapCategory from "../components/Maps/MapCategory";
import Navigation from "../components/Utils/Navigation";
import { TopItem } from "../components/Home/TopItem";

export default function InstancesPage(props) {
  const [categories, setCategories] = useState(null);
  const [time, setTime] = useState(null);
  const [stats, setStats] = useState({
    total: null,
    categories: null,
    active: null,
  });
  const [showing, setShowing] = useState(true);
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    fetch("/api/data/category", {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        var today = new Date();
        var date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        var time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        var dateTime = date + " " + time;

        let d = stats;
        d.total = data?.total;
        d.categories = data?.result?.length;
        d.active = data?.active;
        setTime(dateTime);
        setStats(d);
        setCategories(data?.result);
      })
      .catch((e) => {});
  }, []);

  return (
    <div className="wrapper">
      <Navigation
        showing={showing}
        setShowing={setShowing}
        active="Published Instances"
      />
      <div className="MainContent">
        <Header2
          showing={showing}
          setShowing={setShowing}
          active="Published Instances"
        />
        <div className="InstancesPage">
          <div className="top">
            <TopItem
              title="Total"
              date={time}
              value={stats.total}
              color="#C9EEFF"
            />
            <TopItem
              title="Categories"
              date={time}
              value={stats.categories}
              color="#F1DEC9"
            />
            <TopItem
              title="Active"
              date={time}
              value={stats.active}
              color="#FBFFB1"
            />
          </div>
          {categories &&
            categories.map((item, index) => {
              return <MapCategory key={index} category={item.Category} />;
            })}
        </div>
      </div>
    </div>
  );
}
