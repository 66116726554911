import { useState, useEffect } from "react";
import "../Styles/CommentsPage.scss";
import Header2 from "../components/Utils/Header2";
import Navigation from "../components/Utils/Navigation";
import Messagecontent from "../components/Utils/MessageContent";
import Pagination from "../components/Utils/Pagination";
import Selector from "../components/Utils/Selector";
import UsrStats from "../components/Comments/UsrStats";
import CommentsContent from "../components/Utils/CommentsContent";
import { TopItem } from "../components/Home/TopItem";
import { Card, Divider, Typography } from "@mui/material";

export default function CommentsPage(props) {
  const [data, setData] = useState(null);
  const [offset, setOffset] = useState(0);
  const [changed] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [sNo, setSNo] = useState(offset * 12 + 1);
  const [time, setTime] = useState();

  const [totalforToday, setTotalforToday] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  var jwt = require("jsonwebtoken");

  const [currentUser, setCurrentUser] = useState();
  const [showing, setShowing] = useState(true);
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("nimda_ksa");
    if (token) {
      var decoded = jwt.decode(token);
      setCurrentUser(decoded);
    } else {
      setCurrentUser(null);
    }
  }, []);

  useEffect(() => {
    fetch(`/api/comments/getTotalforToday`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch messages!!!");
        }
        return res.json();
      })
      .then((data) => {
        setTotalforToday(data.total);
        var today = new Date();
        var date =
          today.getFullYear() +
          "-" +
          today.toLocaleString("en-us", { month: "short" }) +
          "-" +
          today.getDate() +
          "th";
        var time = today.getHours() + ":" + today.getMinutes();
        var dateTime = date + " " + time;
        setTime(dateTime);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    setData(null);
    setSNo(offset * 12 + 1);

    fetch(`/api/comments/desc/paginated/${(currentPage - 1) * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch messages!!!");
        }
        return res.json();
      })
      .then((data) => {
        setData(data);

        var today = new Date();
        var date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        var time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        var dateTime = date + " " + time;
        setTime(dateTime);
      })
      .catch((err) => {});
  }, [refresh, changed, currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="wrapper">
      <Navigation showing={showing} setShowing={setShowing} active="Comments" />
      <div className="MainContent">
        <Header2 showing={showing} setShowing={setShowing} active="Comments" />
        <div className="MessagesPage">
          <div className="top">
            <TopItem
              title="Total"
              date={time}
              value={data?.total}
              color="#C9EEFF"
            />
            <TopItem
              title="Read"
              date={time}
              value={data?.read}
              color="#F1DEC9"
            />
            <TopItem
              title="Unread"
              date={time}
              value={data?.unread}
              color="#FBFFB1"
            />
          </div>
          <Card className="list">
            <Typography variant="h3">Map Comments</Typography>
            <Divider />
            <div className="content">
              {data &&
                data.result.map((comment, index) => {
                  return (
                    <CommentsContent
                      key={index}
                      message={comment}
                      update_url={`comments/${comment.CommentID}`}
                      replies_url={`repliesbycommentsid/${comment.CommentID}`}
                      target="comments"
                      currentUser={currentUser}
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  );
                })}
            </div>
            {data && (
              <Pagination
                totalItems={data.total}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
}
